import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { SplitButton } from "primereact/splitbutton";
import logo from "../assets/images/logo.svg";
import Cookies from "js-cookie";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";

export const AppTopbar = (props) => {
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);

    const logout = () => {
        Cookies.remove("token");
        navigate("/login");
    };

    const items = [
        {
            label: "Logout",
            icon: "pi pi-sign-out",
            command: () => setShowModal(true),
        },
    ];

    const deleteSingleItemFooter = () => {
        return (
            <>
                <Button label="No" icon="pi pi-times" className="p-button-text" onClick={() => setShowModal(false)} />
                <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={logout} />
            </>
        );
    };

    return (
        <>
            <div className="layout-topbar">
                <Link to="/" className="layout-topbar-logo">
                    <img width="50%" src={logo} alt="logo" />
                </Link>

                <button type="button" className="p-link  layout-menu-button layout-topbar-button" onClick={props.onToggleMenuClick}>
                    <i className="pi pi-bars" />
                </button>

                <ul className="layout-topbar-menu lg:flex origin-top">
                    <li>
                        <SplitButton onClick={() => navigate("/dashboard/account")} icon="pi pi-user" model={items} className="p-button-text"></SplitButton>
                    </li>
                </ul>
            </div>
            <Dialog visible={showModal} style={{ width: "450px" }} header="Confirm" modal footer={deleteSingleItemFooter} onHide={() => setShowModal(false)}>
                <div className="flex align-items-center py-3">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                    <span>Are you sure you want to logout ?</span>
                </div>
            </Dialog>
        </>
    );
};
