import Api from "./Api";

export default {
  Get() {
    return Api().get("/claim");
  },
  Update(data) {
    return Api().post(`/claim/edit`, data);
  },
  Add(data) {
    return Api().post(`/claim`, data);
  },
  Delete(data) {
    return Api().delete(`/claim`, { data: data });
  },
};
