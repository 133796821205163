import React from "react";
import classnames from "classnames";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Controller, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import toast, { Toaster } from "react-hot-toast";
import UserApi from "../api/UserApi";
import Cookies from "js-cookie";
import Decode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import Logo from "../assets/images/logo.svg";
import PagesApi from "../api/PagesApi";
import useUserStore from "../stores/userStore";

export default function Login() {
  const navigate = useNavigate();
  const userStore = useUserStore();

  const { isLoading, mutate: login } = useMutation(async (data) => UserApi.Login(data), {
    onSettled: (response) => {
      if (response.data.status !== 200) {
        let error = response.data.message;
        toast.error(error, { duration: 4000 });
        return;
      }

      let { token, user } = response.data.data;
      let decodedToken = Decode(token);
      Cookies.set("token", token, decodedToken.exp);

      // set user to store
      if (user.role_id.permissions) {
        permissionHandler(user, user.role_id.permissions);
      } else {
        userStore.resetUser();
        toast.error("You dont have any permission");
        Cookies.remove("token");
        navigate("/login");
      }
    },
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    login(data);
  };

  const permissionHandler = async (user, permission) => {
    // permissions
    const response = await PagesApi.GetMenu();
    if (response.data.status !== 200) {
      throw new Error("An error has occured!");
    }

    const menu = response.data.data;
    const user_permissions = permission;

    // user dont have any permissions
    if (!user_permissions.length) {
      userStore.resetUser();
      localStorage.removeItem("token");
      return;
    }

    // user has permissions
    let user_route_permissions = [];
    let user_allowed_menu = [];

    // find and store route with actions view true
    user_permissions.map((data) => data.actions.view && user_route_permissions.push(data?.page_id?.route));

    // create new menu that related to user permissions
    for (let index = 0; index < menu.length; index++) {
      user_allowed_menu.push({ label: menu[index].label, items: [] });
      menu[index].items.map((item) => {
        let isExist = user_route_permissions.includes(item.to);
        return isExist && user_allowed_menu[index].items.push(item);
      });
    }

    // remove empty group menu
    let final_user_allowed_menu = [];
    user_allowed_menu.map((data) => data.items.length && final_user_allowed_menu.push(data));

    // save user data in store and re derect
    userStore.setRouteMenu(final_user_allowed_menu);
    userStore.setRoutePermissions(permission);
    userStore.setUser(user);

    // save user address if user have address
    if (user?.addresses?.length) {
      userStore.setUserAddresses(user.addresses);
    }

    toast.success("Welcome back!");
    navigate("/dashboard");
  };

  return (
    <>
      <Toaster />
      <form onSubmit={handleSubmit(onSubmit)} className="flex justify-content-center align-content-center w-full min-h-screen">
        <div className="flex align-items-center justify-content-center w-full">
          <div className="surface-card p-4 shadow-2 border-round w-full lg:w-3">
            <div className="text-center my-6">
              <img src={Logo} alt="logo" />
            </div>

            <div className="w-full">
              <div className="w-full">
                <label htmlFor="email" className="block text-900 font-medium mb-2">
                  Email
                </label>
                <Controller
                  rules={{ required: true }}
                  control={control}
                  name="email"
                  render={({ field }) => <InputText id="email" autoComplete="username" placeholder="input email" onBlur={field.onBlur} onChange={(e) => field.onChange(e)} type="text" value={field.value} className={classnames("w-full", { "p-invalid": errors.email })} />}
                />
              </div>
              <div className="w-full mt-4">
                <label htmlFor="email" className="block text-900 font-medium mb-2">
                  Password
                </label>
                <Controller
                  rules={{ required: true }}
                  control={control}
                  name="password"
                  render={({ field }) => <Password value={field.value} autoComplete="current-password" placeholder="input password" onChange={(e) => field.onChange(e)} toggleMask feedback={false} className="w-full" inputClassName={classnames("w-full", { "p-invalid": errors.password })} />}
                />
              </div>
            </div>
            <Button loading={isLoading} label="Login" className="w-full mt-5" />
          </div>
        </div>
      </form>
    </>
  );
}
