// hooks
import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useDebounce } from "use-debounce";

// components
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import toast, { Toaster } from "react-hot-toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";

// api related

// utils
// import { useNavigate } from "react-router-dom";
import Api from "../../api/Api";
import TimeFormatter from "../../utils/TimeFormatter";
import getImage from "../../utils/getImage";
import { InputTextarea } from "primereact/inputtextarea";
import { Controller, useForm } from "react-hook-form";
import moment from "moment";
import { Calendar } from "primereact/calendar";
import { Paginator } from "primereact/paginator";

const Page = ({ permissions }) => {
  //   const navigate = useNavigate();

  // state
  const [globalFilter, setGlobalFilter] = useState(null);
  const [selectItems, setSelectItems] = useState([]);
  const [showExportModal, setShowExportModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDeleteItemModal, setShowDeleteItemModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [questionnaire, setQuestionnaire] = useState({});
  const exportForm = useForm();

  // paggin
  const limit_page = 10;
  const [first, setFirst] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalData, setTotalData] = useState(5);
  const [searchValue] = useDebounce(globalFilter, 500);

  // api calling
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["questionnaire", currentPage, searchValue],
    queryFn: async () => await getQuestionnaire(),
  });

  // query
  const { isLoading: deleteLoading, mutate: deleteMutate } = useMutation(async (data) => await Api().delete("/questionnaire", { data }), {
    onSettled: (response) => {
      if (response.data.status === 200) {
        setSelectItems([]);
        refetch();
        setShowDeleteModal(false);
        setShowDeleteItemModal(false);
        toast.success("Questionnaire Deleted!", { duration: 4000 });
      } else {
        setSelectItems([]);
        refetch();
        setShowDeleteModal(false);
        setShowDeleteItemModal(false);
        toast.error(response.data.message, { duration: 5000 });
      }
    },
  });
  const { isLoading: exportLoading, mutate: exportMutate } = useMutation(async (data) => await Api().post("/questionnaire/export", data), {
    onSettled: async (response, error) => {
      try {
        if (response.data.status !== 200) {
          throw new Error(response.data.message);
        }

        const a_element = document.createElement("a");
        a_element.href = response.data.data;
        a_element.click();
        a_element.download = "asdsadasd";

        setShowExportModal(false);
        exportForm.reset({});
      } catch (error) {
        toast.error(error.message);
      }
    },
  });

  // react hook
  const getQuestionnaire = async () => {
    try {
      let payload = {
        limit: limit_page,
        page: currentPage,
        sort_by: "created_at",
        sort_at: -1,
      };

      if (searchValue && searchValue.length) {
        payload.query = searchValue;
      }

      const response = await Api().get(`/questionnaire`, { params: payload });

      if (response.data.status !== 200) {
        throw new Error(response.data.message || "failed to get data");
      }

      setTotalData(response.data?.pages?.total_data);
      return response.data.data;
    } catch (error) {
      toast.error(error?.message || "failed to get data");
    }
  };

  const showDeleteItemConfirmation = (data) => {
    setSelectItems([data]);
    setShowDeleteItemModal(true);
  };

  const deleteSelectedItem = () => {
    let payload = {
      questionnaire_id: [],
    };

    for (let i = 0; i < selectItems.length; i++) {
      payload.questionnaire_id.push(selectItems[i]._id);
    }

    deleteMutate(payload);
  };

  const confirmDeleteItem = () => {
    const data = selectItems[0];

    let payload = {
      questionnaire_id: [data._id],
    };

    console.log(payload);
    deleteMutate(payload);
  };

  const confirmDeleteSelected = () => {
    setShowDeleteModal(true);
  };

  const exportHandler = (data) => {
    let first_date = moment(data.range_time[0]).format("YYYY-MM-DD");
    let second_date = data.range_time[1] ? moment(data.range_time[1]).format("YYYY-MM-DD") : first_date;

    exportMutate({ start_date: first_date, end_date: second_date });
  };

  const onPageChange = (event) => {
    setCurrentPage(event.page + 1);
    setFirst(event.first);
  };

  const editCategory = (data) => {
    setQuestionnaire(data);
    setShowEditModal(true);
  };

  // child components
  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <Button label="Export (CSV)" className="p-button-info mr-2" onClick={() => setShowExportModal((prev) => !prev)} />
        <div className="my-2">{permissions.delete && <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectItems.length} />}</div>
      </React.Fragment>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        <Button icon="pi pi-eye" className="p-button-rounded  mr-2" onClick={() => editCategory(rowData)} />
        {permissions.delete && <Button icon="pi pi-trash" className="p-button-rounded p-button-danger mt-2" onClick={() => showDeleteItemConfirmation(rowData)} />}
      </div>
    );
  };

  const header = () => {
    return (
      <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
        <h5 className="m-0">Manage Glow Queen Form</h5>
        <span className="block mt-2 md:mt-0 p-input-icon-left">
          <i className="pi pi-search" />
          <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
        </span>
      </div>
    );
  };

  const deleteSingleItemFooter = () => {
    return (
      <>
        <Button label="No" icon="pi pi-times" className="p-button-text" onClick={() => setShowDeleteItemModal(false)} />
        <Button label="Yes" loading={deleteLoading} icon="pi pi-check" className="p-button-text" onClick={confirmDeleteItem} />
      </>
    );
  };

  const deleteMultipleItemFooter = () => {
    return (
      <>
        <Button label="No" icon="pi pi-times" className="p-button-text" onClick={() => setShowDeleteModal(false)} />
        <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedItem} loading={deleteLoading} />
      </>
    );
  };

  useEffect(() => {
    if (searchValue) {
      setFirst(0);
      setCurrentPage(1);
    }
  }, [searchValue]);

  return (
    <>
      <div className="grid crud-demo">
        <div className="col-12">
          <div className="card">
            <Toaster />
            <Toolbar className="mb-4" left={leftToolbarTemplate}></Toolbar>
            <DataTable loading={isLoading} value={data} selection={selectItems} onSelectionChange={(e) => setSelectItems(e.value)} dataKey="_id" className="datatable-responsive" emptyMessage="No Page found." header={header} responsiveLayout="scroll">
              <Column selectionMode="multiple" headerStyle={{ width: "3rem" }}></Column>
              <Column field="name" header="Name" sortable headerStyle={{ width: "20%", minWidth: "10rem" }}></Column>
              <Column field="email" header="E-Mail" sortable headerStyle={{ width: "20%", minWidth: "10rem" }}></Column>
              <Column field="phone_number" header="Phone" sortable headerStyle={{ width: "15%", minWidth: "10rem" }}></Column>
              <Column field="province" header="Province" sortable headerStyle={{ width: "10%", minWidth: "10rem" }}></Column>
              <Column header="Gender" body={(fieldData) => (fieldData.gender === "null" ? "-" : fieldData.gender)} sortable headerStyle={{ width: "10%", minWidth: "10rem" }}></Column>
              <Column field="dob" header="Birth Date" body={(fieldData) => TimeFormatter(fieldData?.dob)} sortable headerStyle={{ width: "10%", minWidth: "10rem" }}></Column>
              {permissions.update || permissions.delete ? <Column header="Actions" body={actionBodyTemplate} headerStyle={{ width: "10%", minWidth: "10rem" }}></Column> : null}
            </DataTable>
            <Paginator first={first} rows={limit_page} totalRecords={totalData} onPageChange={onPageChange} />
          </div>
        </div>
      </div>

      <Dialog visible={showEditModal} style={{ width: "450px" }} header="Form Details" modal className="p-fluid" footer onHide={() => setShowEditModal(false)}>
        <div>
          <div className="field">
            <label htmlFor="name">Name</label>
            <InputText id="name" type="text" disabled value={questionnaire?.name} />
          </div>
          <div className="field">
            <label htmlFor="email">E-Mail</label>
            <InputText id="route" type="text" disabled value={questionnaire?.email} />
          </div>
          <div className="field">
            <label htmlFor="icon">Phone Number</label>
            <InputText id="icon" type="text" disabled value={questionnaire?.phone_number} />
          </div>
          <div className="field">
            <label htmlFor="icon">Gender</label>
            <InputText id="icon" type="text" disabled value={questionnaire?.gender === "null" ? "-" : questionnaire?.gender} />
          </div>
          <div className="field">
            <label htmlFor="icon">Province</label>
            <InputText id="icon" type="text" disabled value={questionnaire?.province} />
          </div>
          <div className="field">
            <label htmlFor="icon">Address</label>
            <InputTextarea rows="5" id="icon" type="text" disabled value={questionnaire?.address} />
          </div>
          <div className="field">
            <label htmlFor="icon">Birth Date</label>
            <InputText id="icon" type="text" disabled value={TimeFormatter(questionnaire?.dob)} />
          </div>
          <div className="field">
            <label htmlFor="icon">Tiktok Account</label>
            <InputText id="icon" type="text" disabled value={questionnaire?.tiktok_account} />
          </div>
          <div className="field">
            <label htmlFor="icon">Instagram Account</label>
            <InputText id="icon" type="text" disabled value={questionnaire?.instagram_account} />
          </div>

          {questionnaire?.questions?.length
            ? questionnaire.questions.map((data, index) => (
                <div className="field" key={index * 5}>
                  <label htmlFor="icon">{data?.question}</label>
                  <InputText id="icon" type="text" disabled value={data?.answer} />
                </div>
              ))
            : null}

          {questionnaire?.images?.length
            ? questionnaire.images.map((data, index) => (
                <div className="field" key={index * 4}>
                  <label htmlFor="icon">Image</label> <br />
                  <img style={{ width: "100%", maxWidth: "400px" }} src={getImage(data?.path, data?.name)} alt="user_image" />
                </div>
              ))
            : null}

          <div className="flex justify-content-center">
            <div className="flex">
              <Button onClick={() => setShowEditModal(false)} label="CLOSE" icon="pi pi-times" className="p-button-text" />
            </div>
          </div>
        </div>
      </Dialog>

      {/* delete single */}
      <Dialog visible={showDeleteItemModal} style={{ width: "450px" }} header="Confirm" modal footer={deleteSingleItemFooter} onHide={() => setShowDeleteItemModal(false)}>
        <div className="flex align-items-center justify-content-center">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
          {selectItems.length && (
            <span>
              Are you sure you want to delete <b>{selectItems[0]?.name}</b>?
            </span>
          )}
        </div>
      </Dialog>

      {/* delete multiple */}
      <Dialog visible={showDeleteModal} style={{ width: "450px" }} header="Confirm" modal footer={deleteMultipleItemFooter} onHide={() => setShowDeleteModal(false)}>
        <div className="flex align-items-center justify-content-center">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
          {selectItems.length && <span>Are you sure you want to delete the selected Pages?</span>}
        </div>
      </Dialog>

      {/* export */}
      <Dialog visible={showExportModal} style={{ width: "450px" }} header="Export Glow Queens" modal className="p-fluid" footer onHide={() => setShowExportModal(false)}>
        <form onSubmit={exportForm.handleSubmit(exportHandler)}>
          <div className="field">
            <label className="block" htmlFor="range_time">
              Range Time
            </label>
            <Controller control={exportForm.control} rules={{ required: true }} name="range_time" render={({ field }) => <Calendar ref={field.ref} value={field.value} onChange={field.onChange} selectionMode="range" />} />
            {exportForm?.formState?.errors?.range_time && (
              <small id="name-help" className="p-error block">
                This field is required
              </small>
            )}
          </div>

          <div className="flex justify-content-end">
            <div className="flex">
              <Button type="button" onClick={() => setShowExportModal(false)} label="Cancel" icon="pi pi-times" className="p-button-text" />
              <Button loading={exportLoading} label="Export" icon="pi pi-check" className="p-button-text" />
            </div>
          </div>
        </form>
      </Dialog>
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location?.pathname === nextProps.location?.pathname;
};

export default React.memo(Page, comparisonFn);
