// hooks
import React, { useState, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import { useQuery, useMutation } from "react-query";

// conponents
import "react-quill/dist/quill.snow.css";
import { InputText } from "primereact/inputtext";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { Link } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";

// config
import ReactQuill from "react-quill";
import reactQuill from "../config/reactQuill";
import "react-quill/dist/quill.snow.css";
import Api from "../api/Api";

const GlossarySetting = ({ permissions }) => {
  // state
  const [section1ImagePreview, setSection1ImagePreview] = useState(null);
  const section1ImageRef = useRef(null);
  const [id, setId] = useState(null);

  // hooks
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const { isLoading } = useQuery("glossary-setting", () => getDetails(), { refetchOnWindowFocus: false });
  const { isLoading: updateLoading, mutate } = useMutation((data) => Api().post("/glossary/page/edit", data), {
    onSettled: (response, error) => {
      if (error) {
        toast.error("An Error Occured while update");
        return;
      }

      if (response.data.status !== 200) {
        toast.error(response.data.message);
        return;
      }

      toast.success("Update Success");
    },
  });

  // functions
  const onSubmit = (data) => {
    const formData = new FormData();

    // images ref
    const section1Image = section1ImageRef.current.files[0];

    if (!section1Image && !data.section1_images[0]) return toast.error("section 1 image required");

    if (section1Image) {
      formData.append("section1_images", section1Image);
    } else {
      formData.append("section1_images", JSON.stringify(data.section1_images[0]));
    }

    // delete unused obj
    delete data?.section1_images;
    delete data?.created_at;
    delete data?._id;
    delete data?.__v;

    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });

    formData.append("glossary_id", id);

    mutate(formData);
  };

  const getDetails = async () => {
    try {
      const response = await Api().get("/glossary/detail");

      if (response.data.status !== 200) {
        toast.error(response.data.message);
        return;
      }

      setId(response?.data?.data?._id);
      const details = response.data.data;
      setSection1ImagePreview(details?.section1_images[0]?.url ? details.section1_images[0].url : null);
      reset(details);

      return details;
    } catch (error) {
      console.log(error);
      toast.error("An Error Occured!");
    }
  };

  // components
  const leftToolbar = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <h4 className="uppercase" style={{ margin: 0 }}>
            Glossary Setting
          </h4>
        </div>
      </React.Fragment>
    );
  };

  const rightToolbar = () => {
    return (
      <React.Fragment>
        <Link to="/dashboard/glossary-list">
          <Button label="Create Glossary List" className="p-button p-button-primary ml-4" />
        </Link>
      </React.Fragment>
    );
  };

  const ImagePreview = ({ image }) => {
    return (
      image && (
        <div className="field col-12 md:col-3">
          <img style={{ maxWidth: "250px" }} src={image} alt={"section 1 preview"} />
        </div>
      )
    );
  };

  return (
    <>
      <Toaster />
      <form onSubmit={handleSubmit(onSubmit)} className="card">
        <Toolbar className="mb-4" left={leftToolbar} right={rightToolbar} />
        <div>
          <div className="field col-12 md:col-12">
            {/* section 1 */}
            <div className="p-fluid formgrid grid ">
              <div className="field col-12 md:col-6">
                <label htmlFor="section1_title">Title Section 1 :</label>
                <Controller
                  rules={{ required: true }}
                  control={control}
                  defaultValue={""}
                  name="section1_title"
                  render={({ field }) => <InputText placeholder="Input Title" value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="section1_title" type="text" className={errors.section1_title && "p-invalid"} />}
                />
              </div>
              <div className="field col-12 md:col-12">
                <div className="field p-fluid">
                  <label>Description Section 1 :</label>
                  <Controller
                    rules={{ required: true }}
                    control={control}
                    name="section1_description"
                    render={({ field }) => <ReactQuill className="custom-quill" onBlur={field.onBlur} ref={field.ref} modules={reactQuill.modules} value={field.value} onChange={(e) => field.onChange(e)} theme="snow" />}
                  />
                </div>
              </div>
              <div className="field col-12 md:col-12 p-fluid formgrid grid">
                <div className="field col-12 md:col-6">
                  <div className="field col-12 md:col-12">
                    <div className="field p-fluid">
                      <label>Top Image :</label> <br />
                      <input
                        onChange={(e) => {
                          setSection1ImagePreview(URL.createObjectURL(e.target.files[0]));
                        }}
                        ref={section1ImageRef}
                        className="mt-2"
                        accept="image/*"
                        type="file"
                      />
                    </div>
                  </div>
                  <ImagePreview image={section1ImagePreview} />
                </div>
              </div>
            </div>
          </div>

          {/* buttons */}
          <div className="flex justify-content-center mt-4">
            {permissions.update && <Button loading={updateLoading} disabled={isLoading} label="Save" className=" p-button-primary mr-4" />}
            <Link to="/dashboard">
              <Button type="button" label="Back" className=" p-button-secondary" />
            </Link>
          </div>
        </div>
      </form>
      {/* <DevTool control={control} /> */}
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location?.path === nextProps.location?.path;
};

export default React.memo(GlossarySetting, comparisonFn);
