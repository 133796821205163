import Api from "./Api";

export default {
  Get() {
    return Api().get("/blog-category");
  },
  Update(data) {
    return Api().post(`/blog-category/edit`, data);
  },
  Add(data) {
    return Api().post(`/blog-category`, data);
  },
  Delete(data) {
    return Api().delete(`/blog-category`, { data: data });
  },
};
