import { useQuery } from "react-query";
import RolesApi from "../api/RolesApi";

export const useGetRole = () => {
    const getRole = async () => {
        const response = await RolesApi.Get();

        if (response.data.status !== 200) {
            throw new Error("An error has occured!");
        }

        return response.data.data;
    };
    return useQuery("role", () => getRole());
};
