import { useQuery } from "react-query";
import ShopApi from "../api/ShopApi";

export const useGetShop = () => {
  const getProducts = async () => {
    const response = await ShopApi.Get();
    if (response.data.status !== 200) {
      throw new Error("An error has occured!");
    }

    return response.data.data;
  };

  return useQuery("shops", () => getProducts());
};
