import Api from "./Api";

export default {
  Get() {
    return Api().get("/quiz");
  },
  Details(id) {
    return Api().get(`/quiz/${id}`);
  },
  Update(data) {
    return Api().post(`/quiz/edit`, data);
  },
  Add(data) {
    return Api().post(`/quiz`, data);
  },
  Delete(data) {
    return Api().delete(`/quiz`, { data: data });
  },
};
