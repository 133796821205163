// hooks
import React from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { useMutation, useQuery } from "react-query";

// conponents
import { InputText } from "primereact/inputtext";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { Link, useNavigate, useParams } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";

// api related
import "react-quill/dist/quill.snow.css";
import Api from "../../api/Api";

const UpdateSitemap = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  // hooks
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const { isLoading: detailsLoading } = useQuery(["sitemaps", id], () => getDetails(), { refetchOnWindowFocus: false });
  const { isLoading: loadingCreate, mutate: Create } = useMutation(async (data) => Api().post("/sitemap/edit", data), {
    onSettled: (response, error) => {
      if (error) {
        toast.error("An Error Occured!");
        return;
      }

      if (response.data.status !== 200) {
        toast.error(response.data.message);
        return;
      }
      toast.success("Sitemaps Updated");
      navigate("/dashboard/sitemap");
    },
  });

  // functions
  const onSubmit = (data) => {
    Create({ ...data, sitemap_id: data._id });
  };

  const getDetails = async () => {
    try {
      const response = await Api().get("/sitemap/" + id);

      if (response.data.status !== 200) {
        toast.error("An Error Occurred");
        throw new Error("An Error Occurred");
      }

      const results = response.data.data;

      delete results.created_by;
      delete results.created_at;
      delete results.organization_id;
      delete results.__v;

      reset(response.data.data);
      return response.data.data;
    } catch (error) {
      toast.error("An Error Occurred");
    }
  };

  // components
  const leftToolbar = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <h4 className="uppercase" style={{ margin: 0 }}>
            Create Sitemap
          </h4>
        </div>
      </React.Fragment>
    );
  };

  const NestedField = ({ control }) => {
    const {
      fields: childFields,
      remove: removeChild,
      append: appendChild,
      insert,
    } = useFieldArray({
      control,
      name: `descriptions`,
    });

    return (
      <>
        {childFields.map((fields, childIndex) => {
          return (
            <div key={fields.id} className="field col-12 formgrid grid">
              <div className="field col-1 flex justify-content-center align-items-center" style={{ height: "100%" }}>
                <div className="" style={{ height: "85%", width: "2px", border: "2px dotted black" }}></div>
              </div>
              <div className="field col-11 formgrid grid">
                <div className="field md:col-5">
                  <label htmlFor="user">Name :</label>
                  <Controller control={control} defaultValue={""} name={`descriptions[${childIndex}].name`} render={({ field }) => <InputText placeholder="Input name" value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" />} />
                </div>
                <div className="field md:col-2">
                  <label htmlFor="user" style={{ visibility: "hidden" }}>
                    Insert Below This Row:
                  </label>
                  <Button type="button" onClick={() => insert(childIndex + 1, {})} label="Insert Below" className="p-button-primary mr-4" />
                </div>
                <div className="field md:col-1">
                  <label htmlFor="user" style={{ visibility: "hidden" }}>
                    'Remove':
                  </label>
                  <Button type="button" onClick={() => removeChild(childIndex)} label="X" className="p-button-danger mr-4" />
                </div>
                <div className="field md:col-5">
                  <label htmlFor="user">Route :</label>
                  <Controller control={control} defaultValue={""} name={`descriptions[${childIndex}].route`} render={({ field }) => <InputText placeholder="Input route" value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" />} />
                </div>
              </div>
            </div>
          );
        })}

        <div className="field col-12 md:col-1"></div>
        <div className="field col-12 md:col-2">
          <Button
            type="button"
            onClick={() =>
              appendChild({
                name: "",
                route: "",
              })
            }
            label="Add List"
            className="p-button-primary mr-4"
          />
        </div>
      </>
    );
  };

  return (
    <>
      <Toaster />
      <form onSubmit={handleSubmit(onSubmit)} className="card">
        <Toolbar className="mb-4" left={leftToolbar} />
        <div className="p-fluid formgrid grid justify-content-center">
          <div className="field col-12 formgrid grid ">
            <div className="field md:col-8">
              <label htmlFor="user">Title :</label>
              <Controller
                rules={{ required: true }}
                control={control}
                defaultValue={""}
                name="title"
                render={({ field }) => <InputText placeholder="Tab Name" value={field.value} onBlur={field.onBlur} className={errors.title && "p-invalid"} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" />}
              />
            </div>
            <div className="field md:col-2">
              {/* <label htmlFor="user">Action :</label> */}
              {/* <Button type="button" onClick={() => remove(parentIndex)} label="Remove Tab" className="p-button-danger mr-4" /> */}
            </div>
            <NestedField control={control} />
          </div>
        </div>
        <div className="flex justify-content-center mt-4">
          <Button disabled={detailsLoading} loading={loadingCreate} label="Save" className=" p-button-primary mr-4" />
          <Link to="/dashboard/sitemaps">
            <Button type="button" label="Back" className=" p-button-secondary" />
          </Link>
        </div>
      </form>
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location?.path === nextProps.location?.path;
};

export default React.memo(UpdateSitemap, comparisonFn);
