import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import toast, { Toaster } from "react-hot-toast";
import { useQuery } from "react-query";
import Api from "../api/Api";
import moment from "moment";

const Dashboard = (props) => {
  const { data: productData } = useQuery("product-dashboard", () => getData("product"), { initialData: [] }, { initialData: [] });
  const { isLoading: reviewLoading, data: reviewData } = useQuery("review-dashboard", () => getData("review"), { initialData: [] });
  const { isLoading: userLoading, data: userData } = useQuery("user-dashboard", () => getData("user"), { initialData: [] });
  const { isLoading: blogLoading, data: blogData } = useQuery("blog-dashboard", () => getData("blog"), { initialData: [] });

  const getData = async (endpointPath, name = "Data") => {
    try {
      const response = await Api().get("/dashboard/" + endpointPath);

      if (response.data.status !== 200) {
        toast.error(response.data.message);
        throw new Error("an error occurred");
      }

      return response.data.data;
    } catch (error) {
      toast.error(`Failed to get ${name}`);
    }
  };

  const formatTime = (time) => {
    return moment(time, "YYYYMMDD").fromNow();
  };

  return (
    <div className="grid">
      <Toaster />
      {/* products */}
      <div className="col-12 md:col-6 lg:col-6 xl:col-3">
        <div className="card mb-0">
          <div className="flex justify-content-between mb-3">
            <div>
              <span className="block text-500 font-medium mb-3">Products</span>
              <div className="text-900 font-medium text-lg">
                <span className="text-green-500 font-medium">
                  {productData?.total_product ? productData?.total_product : "-"}
                  <span className="text-500 pl-2">Items</span>
                </span>
              </div>
            </div>
            <div className="flex align-items-center justify-content-center bg-blue-100 border-round" style={{ width: "2.5rem", height: "2.5rem" }}>
              <i className="pi pi-shopping-cart text-blue-500 text-xl" />
            </div>
          </div>
        </div>
      </div>
      {/* blog */}
      <div className="col-12 md:col-6 lg:col-6 xl:col-3">
        <div className="card mb-0">
          <div className="flex justify-content-between mb-3">
            <div>
              <span className="block text-500 font-medium mb-3">Blog Created</span>
              <div className="text-900 font-medium text-lg">
                <span className="text-green-500 font-medium">
                  {blogData?.total_blog ? blogData?.total_blog : "-"}
                  <span className="text-500 pl-2">Blog</span>
                </span>
              </div>
            </div>
            <div className="flex align-items-center justify-content-center bg-orange-100 border-round" style={{ width: "2.5rem", height: "2.5rem" }}>
              <i className="pi pi-book text-orange-500 text-xl" />
            </div>
          </div>
        </div>
      </div>
      {/* users */}
      <div className="col-12 md:col-6 lg:col-6 xl:col-3">
        <div className="card mb-0">
          <div className="flex justify-content-between mb-3">
            <div>
              <span className="block text-500 font-medium mb-3">Total Users</span>
              <div className="text-900 font-medium text-lg">
                <span className="text-green-500 font-medium">
                  {userData?.total_user ? userData?.total_user : "-"}
                  <span className="text-500 pl-2">Users</span>
                </span>
              </div>
            </div>
            <div className="flex align-items-center justify-content-center bg-cyan-100 border-round" style={{ width: "2.5rem", height: "2.5rem" }}>
              <i className="pi pi-user text-cyan-500 text-xl" />
            </div>
          </div>
        </div>
      </div>
      {/* product Review */}
      <div className="col-12 md:col-6 lg:col-6 xl:col-3">
        <div className="card mb-0">
          <div className="flex justify-content-between mb-3">
            <div>
              <span className="block text-500 font-medium mb-3">Reviews</span>
              <div className="text-900 font-medium text-lg">
                <span className="text-green-500 font-medium">
                  {reviewData?.total_review ? reviewData?.total_review : "-"}
                  <span className="text-500 pl-2">Reviews on products</span>
                </span>
              </div>
            </div>
            <div className="flex align-items-center justify-content-center bg-purple-100 border-round" style={{ width: "2.5rem", height: "2.5rem" }}>
              <i className="pi pi-comment text-purple-500 text-xl" />
            </div>
          </div>
        </div>
      </div>

      {/* chart */}
      {/* <div className="col-12">
        <div className="card">
          <h5 className="text-center uppercase font-semibold">Sales Overview</h5>
          <Chart height="80" type="bar" data={lineData} options={lineOptions} />
        </div>
      </div> */}
      <div className="col-12">
        <div className="card">
          <h5 className="uppercase font-semibold">Recent Reviews on products</h5>
          <DataTable loading={reviewLoading} value={reviewData?.new_reviews} rows={5} responsiveLayout="scroll">
            <Column field="review" header="Review" style={{ width: "50%" }} />
            <Column field="rating" header="Rating" style={{ width: "10%" }} />
            <Column field="price" header="Posted By" body={(rowData) => <span>{rowData?.user_id?.email}</span>} style={{ width: "20%" }} />
            <Column field="price" header="Posted at" body={(rowData) => formatTime(rowData.created_at)} style={{ width: "10%" }} />
          </DataTable>
        </div>
      </div>

      {/* recent users */}
      <div className="col-12 xl:col-6">
        <div className="card">
          <h5 className="uppercase font-semibold">Recent Users</h5>
          <DataTable loading={userLoading} value={userData?.new_users} rows={5} responsiveLayout="scroll">
            <Column field="email" header="E-Mail" style={{ width: "60%" }} />
            <Column header="Register At" body={(rowData) => formatTime(rowData.created_at)} style={{ width: "20%" }} />
          </DataTable>
        </div>
      </div>

      {/* recent product review */}
      <div className="col-12 xl:col-6">
        <div className="card">
          <h5 className="uppercase font-semibold">Recent Blog</h5>
          <DataTable loading={blogLoading} value={blogData?.new_blogs} rows={5} responsiveLayout="scroll">
            <Column field="title" header="Title" style={{ width: "75%" }} />
            <Column field="price" header="Posted At" style={{ width: "25%" }} body={(rowData) => formatTime(rowData.created_at)} />
          </DataTable>
        </div>
      </div>
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location?.pathname === nextProps.location?.pathname && prevProps.colorMode === nextProps.colorMode;
};

export default React.memo(Dashboard, comparisonFn);
