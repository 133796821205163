import { useQuery } from "react-query";
import StepApi from "../api/StepApi";

export const useGetRoutine = () => {
  const getRoutine = async () => {
    const response = await StepApi.Get();

    if (response.data.status !== 200) {
      throw new Error("An error has occured!");
    }

    return response.data.data;
  };

  return useQuery("steps", () => getRoutine());
};
