// hooks
import React, { useState, useEffect, useRef } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
// import { DevTool } from "@hookform/devtools";

// conponents
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { Link, useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";

// api related
import { useGetProducts } from "../hook/product.hook";
import BannersApi from "../api/BannersApi";
import getImage from "../utils/getImage";
import { useGetClaims } from "../hook/claims.hook";
import IngridientApi from "../api/IngridientApi";
import HomeReviewApi from "../api/HomeReviewApi";
import { useGetBlog } from "../hook/blog.hook";
import Api from "../api/Api";
import { InputTextarea } from "primereact/inputtextarea";
import { FileUpload } from "primereact/fileupload";

const tabsOptions = [
  { name: "Bright & Glowing Skin", value: "Bright & Glowing Skin" },
  { name: "Conceal Dark Spot", value: "Conceal Dark Spot" },
  { name: "Acne Free Skin", value: "Acne Free Skin" },
];

const HomeSettings = ({ permissions }) => {
  const navigate = useNavigate();
  const [id, setId] = useState(null);

  // state
  const [productOptions, setProductOptions] = useState([]);

  const [reviewOptions, setReviewOptions] = useState([]);
  const [blogOptions, setBlogOptions] = useState([]);
  const [ingridientOptions, setIngridientOptions] = useState([]);
  const [calimOptions, setCalimOptions] = useState([]);
  const [banners, setBanners] = useState([]);
  const [section2Image, setSection2Image] = useState([]);
  const [section3Image, setSection3Image] = useState([]);
  const [section5Image, setSection5Image] = useState([]);

  const section2ImageRef = useRef(null);
  const section3ImagesRef = useRef(null);
  const section5ImagesRef = useRef(null);

  // hooks
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const { fields, remove, append } = useFieldArray({
    control,
    name: "section5_tabs",
  });

  // api calling
  const { isLoading: bannerLoading } = useQuery("Banners", async () => await getBanners());
  const { isLoading: reviewLoading } = useQuery("Reviews Home", async () => await getHomeReviews());
  const { isLoading: ingridientLoading } = useQuery("ingridients", async () => await getIngridients());
  const { data: blogData, isLoading: loadingBlog, isError: isBlogError, isSuccess: isBlogSuccess } = useGetBlog();
  const { data: claimsData, isLoading: claimsLoading, isSuccess: claimSuccess, isError: calimError } = useGetClaims();
  const { data: productData, isLoading: loadingProduct, isSuccess: successProduct, isError: isProductError } = useGetProducts();
  const { isError: isDetailsError } = useQuery("home-details", async () => await getDetails(), { refetchOnWindowFocus: false });

  // query
  const { isLoading: createLoading, mutate: crateMutate } = useMutation(async (data) => await Api().post("/home/page/edit", data), {
    onSettled: (response, error) => {
      if (error) {
        toast.error("Something went wrong", { duration: 5000 });
        return;
      }

      if (response.data.status !== 200) {
        // navigate("/dashboard");
        toast.error(response.data.err, { duration: 5000 });
        return;
      }

      toast.success("Home content updated", { duration: 5000 });
    },
  });

  // functions
  const getIngridients = async () => {
    try {
      const response = await IngridientApi.Get();

      if (response.data.status !== 200) {
        throw new Error("Something went wrong");
      }

      let temp_data = [];
      const ingridient = response.data.data;
      for (let i = 0; i < ingridient.length; i++) {
        temp_data.push({ name: ingridient[i]._id, value: ingridient[i]._id, image: getImage(ingridient[i]?.images[0]?.path, ingridient[i]?.images[0]?.name) });
      }

      setIngridientOptions(temp_data);
      return response.data.data;
    } catch (error) {
      console.log(error);
    }
  };

  const getBanners = async () => {
    try {
      const response = await BannersApi.Get();

      if (response.data.status !== 200) {
        throw new Error("Something went wrong");
      }

      const banner = response.data.data;
      let temp_data = [];
      for (let i = 0; i < banner.length; i++) {
        temp_data.push({ name: banner[i]._id, value: banner[i]._id, image: getImage(banner[i]?.images[0]?.path, banner[i]?.images[0]?.name) });
      }

      setBanners(temp_data);
      return response.data.data;
    } catch (error) {
      console.log(error);
    }
  };

  const getHomeReviews = async () => {
    try {
      const response = await HomeReviewApi.Get();

      if (response.data.status !== 200) {
        throw new Error("Something went wrong");
      }

      const review = response.data.data;
      let temp_data = [];

      for (let i = 0; i < review.length; i++) {
        temp_data.push({ name: review[i].title, value: review[i]._id });
      }

      setReviewOptions(temp_data);
      return response.data.data;
    } catch (error) {
      console.log(error);
    }
  };

  const getOnlyId = (array) => {
    let temp_data = [];
    array.map((data) => temp_data.push(data._id));

    return temp_data;
  };

  const getDetails = async (id) => {
    const response = await Api().get(`/home/detail`);

    if (response.data.status !== 200) {
      throw new Error("An error has occured!");
    }

    const home = response.data.data;

    // console.log(home);

    let section5temp = [];

    home.section5_tabs.map((tab) => section5temp.push({ ...tab, products: getOnlyId(tab.products) }));

    setSection2Image(getImage(home.section2_images[0]?.path, home.section2_images[0]?.name));
    setSection3Image(home.section3_images);
    setSection5Image(getImage(home.section5_images[0]?.path, home.section5_images[0]?.name));
    setId(home._id);
    reset({
      ...home,
      section1_claims: getOnlyId(home.section1_claims),
      section1_slides: getOnlyId(home.section1_slides),
      section2_products: getOnlyId(home.section2_products),
      section4_ingredients: getOnlyId(home.section4_ingredients),
      section5_tabs: section5temp,
      section6_reviews: getOnlyId(home.section6_reviews),
      section7_blogs: getOnlyId(home.section7_blogs),
    });
    return response.data.data;
  };

  const onSubmit = (data) => {
    const section3ImageNewFile = section3ImagesRef.current.files;
    const section2ImageNewFile = section2ImageRef.current.files[0];
    const section5ImageNewFile = section5ImagesRef.current.files[0];

    const formData = new FormData();

    if (section3ImageNewFile) {
      for (let i = 0; i < section3ImageNewFile.length; i++) {
        formData.append("section3_images", section3ImageNewFile[i]);
      }
    } else {
      for (let i = 0; i < data.section3_images.length; i++) {
        formData.append("section3_images", JSON.stringify(data.section3_images[i]));
      }
    }

    if (section5ImageNewFile) {
      formData.append("section5_images", section5ImageNewFile);
    } else {
      formData.append("section5_images", JSON.stringify(data.section5_images[0]));
    }

    if (section2ImageNewFile) {
      formData.append("section2_images", section2ImageNewFile);
    } else {
      formData.append("section2_images", JSON.stringify(data.section2_images[0]));
    }

    delete data.section3_images;
    delete data.section5_images;
    delete data.section2_images;

    Object.keys(data).forEach((key) => {
      const isArray = Array.isArray(data[key]);
      if (isArray) {
        formData.append(key, JSON.stringify(data[key]));
      } else {
        formData.append(key, data[key]);
      }
    });

    formData.append("home_id", id);

    crateMutate(formData);
  };

  const section2SelectImage = async (e) => {
    let selectedFile = e.target.files[0];
    let createBlob = URL.createObjectURL(selectedFile);
    setSection2Image(createBlob);
  };

  const section5SelectImage = async (e) => {
    let selectedFile = e.target.files[0];
    let createBlob = URL.createObjectURL(selectedFile);
    setSection5Image(createBlob);
  };

  const removeSection2SelectImage = () => {
    let fileInput = document.getElementById("upload-section2");
    fileInput.value = null;
    setSection2Image(null);
  };

  const removeSection5SelectImage = () => {
    let fileInput = document.getElementById("upload-section5");
    fileInput.value = null;
    setSection5Image(null);
  };

  // components
  const leftToolbar = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <h5 className="uppercase" style={{ margin: 0 }}>
            Home Page Setting
          </h5>
        </div>
      </React.Fragment>
    );
  };

  const rightToolbar = () => {
    return (
      <React.Fragment>
        <Link to="/dashboard/products">
          <Button label="Back" className="p-button-outlined p-button-secondary ml-4" />
        </Link>
      </React.Fragment>
    );
  };

  const imageOptionSelect = (option) => {
    return (
      <div className="country-item">
        <img width={50} alt={option.name} src={option.image} />
        {/* <div>{option.name}</div> */}
      </div>
    );
  };

  const Section2Image = () => {
    return section2Image ? (
      <div className="mt-1">
        <div className="grid">
          <div className="col-12 md:col-6 lg:col-3 relative flex justify-content-center align-items-center ">
            <img className="w-full" style={{ objectFit: "contain" }} height="200px" src={section2Image} alt="preview" />
            <Button type="button" onClick={removeSection2SelectImage} icon="pi pi-times" className="p-button-rounded p-button-danger mr-2 mb-2 absolute" />
          </div>
        </div>
      </div>
    ) : (
      ""
    );
  };

  const Section3Images = () => {
    return section3Image.length ? (
      <div className="">
        <p className="mb-4">Current Product Images :</p>
        <div className="grid">
          {section3Image.map((item, index) => (
            <div
              key={index}
              // onClick={() => {
              //   console.log("hello");
              // }}
              style={{ maxHeight: "250px" }}
              className="col-12 md:col-6 lg:col-3 relative flex justify-content-center align-items-center "
            >
              <img style={{ objectFit: "contain" }} className="w-full h-full" src={getImage(item?.path, item?.name)} alt="preview" />
              {/* <Button t icon="pi pi-times" className="p-button-rounded p-button-danger mr-2 mb-2 absolute" /> */}
            </div>
          ))}
        </div>
      </div>
    ) : (
      ""
    );
  };

  const Section5Image = () => {
    return section5Image ? (
      <div className="mt-4">
        <div className="grid">
          <div className="col-12 md:col-6 lg:col-3 relative flex justify-content-center align-items-center ">
            <img className="w-full" style={{ objectFit: "contain" }} height="200px" src={section5Image} alt="preview" />
            <Button type="button" onClick={removeSection5SelectImage} icon="pi pi-times" className="p-button-rounded p-button-danger mr-2 mb-2 absolute" />
          </div>
        </div>
      </div>
    ) : (
      ""
    );
  };

  const emptyTemplate = () => {
    return (
      <div className="flex align-items-center flex-column">
        <i className="pi pi-image mt-3 p-5" style={{ fontSize: "5em", borderRadius: "50%", backgroundColor: "var(--surface-b)", color: "var(--surface-d)" }}></i>
        <span style={{ fontSize: "1.2em", color: "var(--text-color-secondary)" }} className="my-5">
          Drag and Drop Image Here
        </span>
      </div>
    );
  };

  const fileUploadHeaderTemplate = (options) => {
    const { className, chooseButton } = options;
    return (
      <div className={className} style={{ backgroundColor: "transparent", display: "flex", alignItems: "center" }}>
        {chooseButton}
      </div>
    );
  };

  // lifecycle
  useEffect(() => {
    let temp_data = [];

    if (calimError) {
      toast.error("Please check your connections", { duration: 6000 });
      return;
    }

    if (!claimsLoading && claimSuccess) {
      for (let i = 0; i < claimsData.length; i++) {
        temp_data.push({ name: claimsData[i].name, value: claimsData[i]._id });
      }
    }

    setCalimOptions(temp_data);
  }, [claimsLoading, calimError, claimsData, claimSuccess]);

  useEffect(() => {
    let temp_data = [];

    if (isProductError) {
      toast.error("Please check your connections", { duration: 6000 });
      return;
    }

    if (!loadingProduct && successProduct) {
      for (let i = 0; i < productData.length; i++) {
        temp_data.push({ name: productData[i].name, value: productData[i]._id });
      }
    }

    setProductOptions(temp_data);
  }, [loadingProduct, successProduct, productData, isProductError]);

  useEffect(() => {
    let temp_data = [];

    if (isBlogError) {
      toast.error("Please check your connections", { duration: 6000 });
      return;
    }

    if (!loadingBlog && isBlogSuccess) {
      for (let i = 0; i < blogData.length; i++) {
        temp_data.push({ name: blogData[i].title, value: blogData[i]._id });
      }
    }

    setBlogOptions(temp_data);
  }, [loadingBlog, isBlogSuccess, isBlogError, blogData]);

  if (isDetailsError) {
    return navigate("/dashboard/products");
  }

  return (
    <>
      <Toaster />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Toolbar className="mb-4" left={leftToolbar} right={rightToolbar} />
        <div>
          {/* section 1 */}
          <div className="field col-12 md:col-12 card">
            <h5 className="uppercase">Section 1</h5>
            <div className="p-fluid formgrid grid ">
              <div className="field col-12 md:col-6">
                <label htmlFor="user">Choose Banner to display :</label>
                <Controller
                  rules={{ required: true }}
                  control={control}
                  name="section1_slides"
                  render={({ field }) => (
                    <MultiSelect
                      itemTemplate={imageOptionSelect}
                      onBlur={field.onBlur}
                      ref={field.ref}
                      loading={bannerLoading}
                      id="section1_slides"
                      value={field.value}
                      onChange={(e) => field.onChange(e)}
                      className={errors.section1_slides && "p-invalid"}
                      disabled={!banners.length}
                      options={banners}
                      optionLabel="name"
                      placeholder="Select product category"
                    />
                  )}
                />

                {errors.section1_slides && (
                  <small id="name-help" className="p-error block">
                    At least please select one
                  </small>
                )}
              </div>
              <div className="field col-12 md:col-6">
                <label htmlFor="section1_claims">Claims max 4 items : </label>
                <Controller
                  rules={{ required: true }}
                  control={control}
                  name="section1_claims"
                  render={({ field }) => (
                    <MultiSelect
                      onBlur={field.onBlur}
                      ref={field.ref}
                      id="section1_claims"
                      value={field.value}
                      onChange={(e) => field.onChange(e)}
                      className={errors.section1_claims && "p-invalid"}
                      disabled={!calimOptions.length}
                      options={calimOptions}
                      optionLabel="name"
                      placeholder="Select calims"
                    />
                  )}
                />

                {errors.category_id && (
                  <small id="name-help" className="p-error block">
                    At least please select one
                  </small>
                )}
              </div>
            </div>
          </div>

          {/* section 2 */}
          <div className="field col-12 md:col-12 card">
            <h5 className="uppercase">Section 2</h5>
            <div className="p-fluid formgrid grid ">
              <div className="field col-12 md:col-6">
                <label htmlFor="user">Section Title :</label>
                <Controller name="section2_title" control={control} rules={{ required: true }} render={({ field, fieldState }) => <InputText id={field.section2_title} {...field} autoFocus />} />
                {errors.name && (
                  <small id="name-help" className="p-error block">
                    Name is required
                  </small>
                )}
              </div>
              <div className="field col-12 md:col-6">
                <label htmlFor="section2_products">Choose products : </label>
                <Controller
                  rules={{ required: true }}
                  control={control}
                  name="section2_products"
                  render={({ field }) => (
                    <MultiSelect
                      onBlur={field.onBlur}
                      ref={field.ref}
                      id="section2_products"
                      value={field.value}
                      onChange={(e) => field.onChange(e)}
                      className={errors.section2_products && "p-invalid"}
                      disabled={!productOptions.length}
                      options={productOptions}
                      optionLabel="name"
                      placeholder="Select product category"
                    />
                  )}
                />

                {errors.section2_products && (
                  <small id="name-help" className="p-error block">
                    At least please select one
                  </small>
                )}
              </div>
              <div className="field col-12 md:col-12">
                <label>Section 2 Image :</label>
                <div className="">
                  <input ref={section2ImageRef} id="upload-section2" onChange={section2SelectImage} accept="image/*" type="file" />
                </div>
                <Section2Image />
              </div>
            </div>
          </div>

          {/* section 3 */}
          <div className="field col-12 md:col-12 card">
            <h5 className="uppercase">Section 3</h5>
            <div className="p-fluid formgrid grid ">
              <div className="field col-12 md:col-12">
                <label htmlFor="user">Running Text :</label>
                <Controller
                  rules={{ required: true }}
                  control={control}
                  name="section3_running_text"
                  defaultValue={""}
                  render={({ field }) => <InputTextarea value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} className={errors.section3_running_text && "p-invalid"} placeholder="Write running text" autoResize rows="10" cols="30" />}
                />

                {errors.section3_running_text && (
                  <small id="name-help" className="p-error block">
                    title is required
                  </small>
                )}
              </div>
            </div>
            <div className=" formgrid grid ">
              <div className="field col-12 md:col-12">
                <label>Section 3 Image :</label>
                <FileUpload ref={section3ImagesRef} className="mt-2" name="demo[]" customUpload u headerTemplate={fileUploadHeaderTemplate} multiple accept="image/*" maxFileSize={10000000} emptyTemplate={emptyTemplate} />
              </div>
              <Section3Images />
            </div>
          </div>

          {/* section 4 */}
          <div className="field col-12 md:col-12 card">
            <h5 className="uppercase">Section 4</h5>
            <div className="p-fluid formgrid grid ">
              <div className="field col-12 md:col-6">
                <label htmlFor="section4_title">Section Title :</label>
                <Controller name="section4_title" control={control} rules={{ required: true }} render={({ field, fieldState }) => <InputText id={field.section4_title} {...field} autoFocus />} placeholder="title..." />
                {errors.section4_title && (
                  <small id="name-help" className="p-error block">
                    Title is required
                  </small>
                )}
              </div>
              <div className="field col-12 md:col-6">
                <label htmlFor="section4_ingredients">Choose ingredients : </label>
                <Controller
                  rules={{ required: true }}
                  control={control}
                  name="section4_ingredients"
                  render={({ field }) => (
                    <MultiSelect
                      onBlur={field.onBlur}
                      ref={field.ref}
                      itemTemplate={imageOptionSelect}
                      id="section4_ingredients"
                      value={field.value}
                      loading={ingridientLoading}
                      onChange={(e) => field.onChange(e)}
                      className={errors.section4_ingredients && "p-invalid"}
                      disabled={!ingridientOptions.length}
                      options={ingridientOptions}
                      optionLabel="name"
                      placeholder="Select ingridient"
                    />
                  )}
                />

                {errors.section4_ingredients && (
                  <small id="name-help" className="p-error block">
                    At least please select one
                  </small>
                )}
              </div>
            </div>
          </div>

          {/* section 5 */}
          <div className="field col-12 md:col-12 card">
            <h5 className="uppercase">Section 5</h5>
            <div className="field p-fluid">
              {/* <label htmlFor="section4_title"> :</label> */}

              <div className="p-fluid formgrid grid ">
                <div className="field col-12 md:col-6">
                  <label htmlFor="section5_title">Section Title :</label>
                  <Controller name="section5_title" control={control} rules={{ required: true }} render={({ field, fieldState }) => <InputText id={field.section5_title} {...field} autoFocus />} placeholder="title..." />
                  {errors.section5_title && (
                    <small id="name-help" className="p-error block">
                      Title is required
                    </small>
                  )}
                </div>
                <div className="field col-12 md:col-6">
                  <label htmlFor="section5_sub_title">Section Sub Title :</label>
                  <Controller name="section5_sub_title" control={control} rules={{ required: true }} render={({ field, fieldState }) => <InputText id={field.section5_sub_title} {...field} autoFocus />} placeholder="title..." />
                  {errors.section5_sub_title && (
                    <small id="name-help" className="p-error block">
                      Sub Title is required
                    </small>
                  )}
                </div>
                <div className="field col-12 md:col-12">
                  {fields.map((field, index) => {
                    return (
                      <div key={field.id} className="p-fluid formgrid grid">
                        <div className="field col-12 md:col-6">
                          <Controller
                            rules={{ required: true }}
                            control={control}
                            name={`section5_tabs.${index}.title`}
                            render={({ field }) => <Dropdown onBlur={field.onBlur} id="status" ref={field.ref} value={field.value} onChange={(e) => field.onChange(e)} options={tabsOptions} optionLabel="name" placeholder="Choose tabs" />}
                          />
                        </div>
                        <div className="field col-12 md:col-5">
                          <Controller
                            rules={{ required: true }}
                            control={control}
                            name={`section5_tabs.${index}.products`}
                            render={({ field }) => (
                              <MultiSelect
                                onBlur={field.onBlur}
                                ref={field.ref}
                                id={`section5_tabs.${index}.products`}
                                value={field.value}
                                onChange={(e) => field.onChange(e)}
                                className={errors.section2_products && "p-invalid"}
                                disabled={!productOptions.length}
                                options={productOptions}
                                optionLabel="name"
                                placeholder="Select product"
                              />
                            )}
                          />
                        </div>
                        <div className="field col-12 md:col-1">
                          <Button type="button" onClick={() => remove(index)} label="x" className="p-button-danger mr-4" />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className=""></div>
              <div className="flex justify-content-left">
                <div className=" col-12 md:col-2">
                  <Button type="button" onClick={() => append()} label="Add +" className=" p-button-primary mr-4" />
                </div>
              </div>
            </div>
            <div className="field col-12 md:col-12">
              <label>Section 5 Image :</label>
              <div className="">
                <input ref={section5ImagesRef} id="upload-section5" onChange={section5SelectImage} accept="image/*" type="file" />
              </div>
              <Section5Image />
            </div>
          </div>

          {/* section 6 */}
          <div className="field col-12 md:col-12 card">
            <h5 className="uppercase">Section 6</h5>
            <div className="p-fluid formgrid grid ">
              <div className="field col-12 md:col-6">
                <label htmlFor="section6_title">Reviews Title :</label>
                <Controller name="section6_title" control={control} rules={{ required: true }} render={({ field, fieldState }) => <InputText id={field.section6_title} {...field} autoFocus />} />
                {errors.section6_title && (
                  <small id="name-help" className="p-error block">
                    title is required
                  </small>
                )}
              </div>
              <div className="field col-12 md:col-6">
                <label htmlFor="section6_reviews">Choose reviews : </label>
                <Controller
                  rules={{ required: true }}
                  control={control}
                  name="section6_reviews"
                  render={({ field }) => (
                    <MultiSelect
                      onBlur={field.onBlur}
                      ref={field.ref}
                      id="section6_reviews"
                      value={field.value}
                      onChange={(e) => field.onChange(e)}
                      className={errors.section6_reviews && "p-invalid"}
                      disabled={!reviewOptions.length}
                      loading={reviewLoading}
                      options={reviewOptions}
                      optionLabel="name"
                      placeholder="Select reviews"
                    />
                  )}
                />

                {errors.section6_reviews && (
                  <small id="name-help" className="p-error block">
                    At least please select one
                  </small>
                )}
              </div>
            </div>
          </div>

          {/* section 7 */}
          <div className="field col-12 md:col-12 card">
            <h5 className="uppercase">Section 7</h5>
            <div className="p-fluid formgrid grid ">
              <div className="field col-12 md:col-6">
                <label htmlFor="section7_title">News Title :</label>
                <Controller name="section7_title" control={control} rules={{ required: true }} render={({ field, fieldState }) => <InputText id={field.section7_title} {...field} autoFocus />} />
                {errors.section7_title && (
                  <small id="name-help" className="p-error block">
                    title is required
                  </small>
                )}
              </div>
              <div className="field col-12 md:col-6">
                <label htmlFor="section7_description">News Descriptions :</label>
                <Controller name="section7_description" control={control} render={({ field, fieldState }) => <InputText id={field.section7_description} {...field} autoFocus />} />
                {errors.section7_description && (
                  <small id="name-help" className="p-error block">
                    description is required
                  </small>
                )}
              </div>
              <div className="field col-12 md:col-3">
                <label htmlFor="section7_button_name">News Button Name :</label>
                <Controller name="section7_button_name" control={control} rules={{ required: true }} render={({ field, fieldState }) => <InputText id={field.section7_button_name} {...field} autoFocus />} />
                {errors.section7_button_name && (
                  <small id="name-help" className="p-error block">
                    Button Name is required
                  </small>
                )}
              </div>
              <div className="field col-12 md:col-3">
                <label htmlFor="section7_button_route">News Button Route :</label>
                <Controller name="section7_button_route" control={control} rules={{ required: true }} render={({ field, fieldState }) => <InputText id={field.section7_button_route} {...field} autoFocus />} />
                {errors.section7_button_route && (
                  <small id="name-help" className="p-error block">
                    Button Route is required
                  </small>
                )}
              </div>
              <div className="field col-12 md:col-6">
                <label htmlFor="section7_blogs">Choose news : </label>
                <Controller
                  rules={{ required: true }}
                  control={control}
                  name="section7_blogs"
                  render={({ field }) => (
                    <MultiSelect
                      onBlur={field.onBlur}
                      ref={field.ref}
                      id="section7_blogs"
                      value={field.value}
                      onChange={(e) => field.onChange(e)}
                      className={errors.section7_blogs && "p-invalid"}
                      disabled={!blogOptions.length}
                      options={blogOptions}
                      optionLabel="name"
                      placeholder="Select product category"
                    />
                  )}
                />

                {errors.section7_blogs && (
                  <small id="name-help" className="p-error block">
                    At least please select one
                  </small>
                )}
              </div>
            </div>
          </div>

          {/* section 8 */}
          <div className="field col-12 md:col-12 card">
            <h5 className="uppercase">Section 8</h5>
            <div className="p-fluid formgrid grid ">
              <div className="field col-12 md:col-6">
                <label htmlFor="section8_title">Section Title :</label>
                <Controller name="section8_title" control={control} rules={{ required: true }} render={({ field, fieldState }) => <InputText id={field.section8_title} {...field} autoFocus />} />
                {errors.section8_title && (
                  <small id="name-help" className="p-error block">
                    title is required
                  </small>
                )}
              </div>

              <div className="field col-12 md:col-3">
                <label htmlFor="section8_button_name">Section Button Name :</label>
                <Controller name="section8_button_name" control={control} rules={{ required: true }} render={({ field, fieldState }) => <InputText id={field.section8_button_name} {...field} autoFocus />} />
                {errors.section8_button_name && (
                  <small id="name-help" className="p-error block">
                    Button Name is required
                  </small>
                )}
              </div>
              <div className="field col-12 md:col-3">
                <label htmlFor="section8_button_route">Section Button Route :</label>
                <Controller name="section8_button_route" control={control} rules={{ required: true }} render={({ field, fieldState }) => <InputText id={field.section8_button_route} {...field} autoFocus />} />
                {errors.section8_button_route && (
                  <small id="name-help" className="p-error block">
                    Button Route is required
                  </small>
                )}
              </div>
            </div>
          </div>

          {/* buttons */}
          <div className="flex justify-content-center mt-4 card">
            {permissions.update && <Button label="Save" loading={createLoading} className=" p-button-primary mr-4" />}
            <Link to="/dashboard">
              <Button type="button" label="Back" className=" p-button-secondary" />
            </Link>
          </div>
        </div>
      </form>

      {/* <DevTool control={control} /> */}
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location?.path === nextProps.location?.path;
};

export default React.memo(HomeSettings, comparisonFn);
