// hooks
import React, { useEffect, useState, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
// import { DevTool } from "@hookform/devtools";
import { useMutation, useQuery } from "react-query";
import { useGetBlog, useGetBlogCategories } from "../../hook/blog.hook";
import { useGetProducts } from "../../hook/product.hook";

// conponents
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { Link, useNavigate, useParams } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

// config
import reactQuill from "../../config/reactQuill";
import Blog from "../../api/Blog";
import { InputTextarea } from "primereact/inputtextarea";

// options static
const publishStatus = [
  { name: "Publish", value: 1 },
  { name: "Saved as draft", value: 0 },
];

const UpdateBlog = () => {
  const selectedImage = useRef(null);
  const selectedThumb = useRef(null);
  const navigate = useNavigate();
  const { id } = useParams();

  const { data: productsData, isLoading: loadingProducts, isError: isProductsError } = useGetProducts();
  const { data: categoriesData, isLoading: loadingCategories, isError: isCategoriesError } = useGetBlogCategories();
  const { data: blogsData, isLoading: loadingBlogs, isError: isBlogsError } = useGetBlog();

  const { isError: isBlogDetailsError } = useQuery(["blog", id], async () => await getDetails(id), { refetchOnWindowFocus: false });
  const { isLoading: loadingUpdate, mutate: updateBlog } = useMutation(async (data) => await Blog.Update(data), {
    onSettled: (response, error) => {
      if (response.data.status === 200) {
        navigate("/dashboard/blog");
      } else {
        toast.error(response.data.message, { duration: 5000 });
      }
    },
  });

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm();

  // state
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [blogs, setBlogs] = useState([]);
  const [image, setImage] = useState(null);
  const [thumbnail, setThumbnail] = useState(null);

  // functions
  const onSubmit = (data) => {
    // remove unused obj
    delete data.created_at;
    delete data.__v;
    delete data.__id;

    const formData = new FormData();
    let selectedFile = selectedImage.current.files[0];
    let selectedthumb = selectedThumb.current.files[0];

    if (!data.images[0] && !selectedFile) {
      toast.error("Image required");
      return;
    }

    if (!data.thumbnail_images[0] && !selectedthumb) {
      toast.error("thumbnails required");
      return;
    }

    if (selectedFile) {
      formData.append("files", selectedFile);
      delete data.images;
    } else {
      formData.append("images", JSON.stringify(data.images[0]));
      delete data.images;
    }

    if (selectedthumb) {
      formData.append("thumbnail_files", selectedthumb);
      delete data.thumbnail_images;
    } else {
      formData.append("thumbnail_images", JSON.stringify(data.thumbnail_images[0]));
      delete data.thumbnail_images;
    }

    // active status is 1 / 0 because react hook form dosnt support boolean value
    data.active_status = Boolean(data.active_status);

    Object.keys(data).forEach((key) => {
      let isArray = Array.isArray(data[key]);
      // if array loop with same key
      if (isArray) {
        for (let i = 0; i < data[key].length; i++) {
          let item = data[key];
          formData.append(key, item[i]);
        }
      } else if (key !== "images") {
        formData.append(key, data[key]);
      }
    });

    formData.append("blog_id", id);

    updateBlog(formData);
  };

  const getDetails = async (id) => {
    const response = await Blog.Details(id);

    if (response.data.status !== 200) {
      throw new Error("An error has occured!");
    }

    let details = response.data.data;
    let status_active = details.active_status ? 1 : 0;

    if (details.images.length) {
      setImage(details.images[0].url);
    }

    if (details.thumbnail_images.length) {
      setThumbnail(details.thumbnail_images[0].url);
    }

    let current_category = [];
    let current_related_blog = [];
    let current_product_id = [];

    await details?.category_id?.map((cat) => current_category.push(cat._id));
    await details?.related?.map((cat) => current_related_blog.push(cat._id));
    await details?.product_id?.map((cat) => current_product_id.push(cat._id));

    reset({ ...details, active_status: status_active, related: current_related_blog, category_id: current_category, product_id: current_product_id });
    return details;
  };

  const selectImage = async (e) => {
    let selectedFile = e.target.files[0];
    let createBlob = URL.createObjectURL(selectedFile);
    setImage(createBlob);
  };

  const selectThumbnail = async (e) => {
    let selectedFile = e.target.files[0];
    let createBlob = URL.createObjectURL(selectedFile);
    setThumbnail(createBlob);
  };

  const removeImage = () => {
    let fileInput = document.getElementById("upload-single");
    fileInput.value = null;

    setImage(null);
  };

  const removeImageThumb = () => {
    let fileInput = document.getElementById("upload-thumb");
    fileInput.value = null;

    setThumbnail(null);
  };

  // components
  const leftToolbar = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <h4 className="uppercase" style={{ margin: 0 }}>
            Update blog
          </h4>
        </div>
      </React.Fragment>
    );
  };

  const rightToolbar = () => {
    return (
      <React.Fragment>
        <div>
          <label htmlFor="status" className="mr-2">
            Status :
          </label>
          <Controller
            rules={{ required: true }}
            control={control}
            name="active_status"
            render={({ field }) => <Dropdown onBlur={field.onBlur} ref={field.ref} id="status" value={field.value} className={errors.active_status && "p-invalid"} onChange={(e) => field.onChange(e)} options={publishStatus} optionLabel="name" placeholder="Blog status"></Dropdown>}
          />
        </div>
        <Link to="/dashboard/blog">
          <Button type="button" label="Back" className="p-button-outlined p-button-secondary ml-4" />
        </Link>
      </React.Fragment>
    );
  };

  const CurrentImages = () => {
    return image ? (
      <div className="mb-4">
        <p className="mb-2">Current Images :</p>
        <div className="grid">
          <div className="col-12 md:col-6 lg:col-3 relative flex justify-content-center align-items-center ">
            <img className="w-full" src={image} alt="" />
            <Button type="button" onClick={removeImage} icon="pi pi-times" className="p-button-rounded p-button-danger mr-2 mb-2 absolute" />
          </div>
        </div>
      </div>
    ) : (
      ""
    );
  };

  const CurrentThumb = () => {
    return thumbnail ? (
      <div className="mt-4">
        <div className="grid">
          <div className="col-12 md:col-6 lg:col-3 relative flex justify-content-center align-items-center ">
            <img className="w-full" src={thumbnail} alt="" />
            <Button type="button" onClick={removeImageThumb} icon="pi pi-times" className="p-button-rounded p-button-danger mr-2 mb-2 absolute" />
          </div>
        </div>
      </div>
    ) : (
      ""
    );
  };

  // life cycle
  useEffect(() => {
    if (!loadingBlogs) {
      let temp_data = [];
      for (let i = 0; i < blogsData.length; i++) {
        temp_data.push({ name: blogsData[i].title, value: blogsData[i]._id });
      }
      setBlogs(temp_data);
    }
  }, [loadingBlogs, blogsData]);

  useEffect(() => {
    if (!loadingProducts) {
      let temp_data = [];

      for (let i = 0; i < productsData.length; i++) {
        temp_data.push({ name: productsData[i].name, value: productsData[i]._id });
      }

      setProducts(temp_data);
    }
  }, [loadingProducts, productsData]);

  useEffect(() => {
    if (!loadingCategories) {
      let temp_data = [];
      for (let i = 0; i < categoriesData.length; i++) {
        temp_data.push({ name: categoriesData[i].name, value: categoriesData[i]._id });
      }
      setCategories(temp_data);
    }
  }, [loadingCategories, categoriesData]);

  useEffect(() => {
    if (isProductsError) {
      toast.error("Please check your connections", { duration: 6000 });
    }
  }, [isProductsError]);

  useEffect(() => {
    if (isCategoriesError) {
      toast.error("Please check your connections", { duration: 6000 });
    }
  }, [isCategoriesError]);

  useEffect(() => {
    if (isBlogsError) {
      toast.error("Please check your connections", { duration: 6000 });
    }
  }, [isBlogsError]);

  useEffect(() => {
    if (isBlogDetailsError) {
      navigate("/dashboard/blog");
    }
  }, [isBlogDetailsError, navigate]);

  return (
    <>
      <Toaster />
      <form onSubmit={handleSubmit(onSubmit)} className="card">
        <Toolbar className="mb-4" left={leftToolbar} right={rightToolbar} />
        <div>
          <div>
            <div className="p-fluid formgrid grid ">
              <div className="field col-12 md:col-6">
                <label htmlFor="title">Title :</label>
                <Controller
                  defaultValue={""}
                  rules={{ required: true }}
                  control={control}
                  name="title"
                  render={({ field }) => <InputText id="title" placeholder="Input title" onBlur={field.onBlur} onChange={(e) => field.onChange(e)} type="text" value={field.value} className={errors.title && "p-invalid"} />}
                />
              </div>
              <div className="field col-12 md:col-6">
                <label htmlFor="related_product">Category : </label>
                <Controller
                  rules={{ required: true }}
                  control={control}
                  name="category_id"
                  render={({ field }) => (
                    <MultiSelect className={errors.category_id && "p-invalid"} onBlur={field.onBlur} ref={field.ref} id="category_id" value={field.value} onChange={(e) => field.onChange(e)} disabled={loadingCategories} options={categories} optionLabel="name" placeholder="Select category" />
                  )}
                />
              </div>
              <div className="field col-12 md:col-6">
                <label htmlFor="related">Related Blog : </label>
                <Controller
                  rules={{ required: false }}
                  control={control}
                  name="related"
                  render={({ field }) => (
                    <MultiSelect
                      onBlur={field.onBlur}
                      ref={field.ref}
                      id="related"
                      value={field.value}
                      onChange={(e) => field.onChange(e)}
                      disabled={!blogs.length}
                      options={blogs}
                      optionLabel="name"
                      placeholder={!loadingBlogs && !blogs.length ? "No data available" : "Select related blog (optional)"}
                    />
                  )}
                />
              </div>
              <div className="field col-12 md:col-6">
                <label htmlFor="product_id">Related Product : </label>
                <Controller
                  rules={{ required: false }}
                  control={control}
                  name="product_id"
                  render={({ field }) => <MultiSelect disabled={loadingProducts} onBlur={field.onBlur} ref={field.ref} id="product_id" value={field.value} onChange={(e) => field.onChange(e)} options={products} optionLabel="name" placeholder="Select product (optional)" />}
                />
              </div>

              <div className="field col-12 md:col-12">
                <div className="field p-fluid">
                  <label>Body Content : {errors.content && <span style={{ color: "#D9362B" }}>Content Required</span>}</label>
                  <Controller rules={{ required: true }} control={control} name="content" render={({ field }) => <ReactQuill onBlur={field.onBlur} ref={field.ref} modules={reactQuill.modules} value={field.value} onChange={(e) => field.onChange(e)} theme="snow" />} />
                </div>
              </div>
            </div>
          </div>

          <div className="p-fluid formgrid grid ">
            <div className="field col-12 md:col-6">
              <label htmlFor="title">Meta Title :</label>
              <Controller
                defaultValue={""}
                rules={{ required: true }}
                control={control}
                name="meta_title"
                render={({ field }) => <InputText id="meta_title" placeholder="Input meta title" onBlur={field.onBlur} onChange={(e) => field.onChange(e)} type="text" value={field.value} className={errors.meta_title && "p-invalid"} />}
              />
            </div>

            <div className="field col-12 md:col-12">
              <div className="field p-fluid">
                <label>Meta Description : {errors.meta_description && <span style={{ color: "#D9362B" }}>Meta Description Required</span>}</label>
                <Controller
                  rules={{ required: true }}
                  control={control}
                  name="meta_description"
                  defaultValue={""}
                  render={({ field }) => <InputTextarea value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} className={errors.meta_description && "p-invalid"} placeholder="Write Meta Descriptions" autoResize rows="10" cols="30" />}
                />
              </div>
            </div>
          </div>

          <div>
            <div className="">
              <p className="mb-2">Blog Images :</p>
              <input ref={selectedImage} id="upload-single" onChange={selectImage} accept="image/*" type="file" />
            </div>
          </div>
          <div className="">
            <CurrentImages />
          </div>
          <div>
            <div className="mt-4">
              <p className="mb-2">Thumbnails Image :</p>
              <input ref={selectedThumb} id="upload-thumb" onChange={selectThumbnail} accept="image/*" type="file" />
            </div>
          </div>
          <div className="">
            <CurrentThumb />
          </div>

          <div className="flex justify-content-center mt-4">
            <Button loading={loadingUpdate} label="Save" className=" p-button-primary mr-4" />
            <Link to="/dashboard/blog">
              <Button type="button" label="Back" className=" p-button-secondary" />
            </Link>
          </div>
        </div>
      </form>
      {/* <DevTool control={control} /> */}
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location?.path === nextProps.location?.path;
};

export default React.memo(UpdateBlog, comparisonFn);
