// hooks
import React, { useState, useEffect, useRef } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { useMutation } from "react-query";
// import { DevTool } from "@hookform/devtools";

// conponents
import "react-quill/dist/quill.snow.css";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";
import { Link, useNavigate } from "react-router-dom";
// import { TwitterPicker } from "react-color";
import toast, { Toaster } from "react-hot-toast";
import { InputTextarea } from "primereact/inputtextarea";
import { InputNumber } from "primereact/inputnumber";
import { InputSwitch } from "primereact/inputswitch";

// config
import ReactQuill from "react-quill";
import reactQuill from "../../config/reactQuill";
import "react-quill/dist/quill.snow.css";
// import formDataDebugger from "../../utils/formDataDebugger";

// api related
import ProductApi from "../../api/ProductApi";
import { useGetFragrance } from "../../hook/fragrance.hook";
import { useGetProductCategories, useGetProducts, useGetProductTypes } from "../../hook/product.hook";
import { useGetShop } from "../../hook/shop.hook";
import { useGetRoutine } from "../../hook/routine.hook";

// options static
const publishStatus = [
  { name: "Publish", value: 1 },
  { name: "Saved as draft", value: 0 },
];

const CreateProduct = () => {
  const navigate = useNavigate();

  // state
  const [categoryOptions, setCategoryOption] = useState([]);
  const [productOptions, setProductOptions] = useState([]);
  const [fragranceOptions, setFragranceOptions] = useState([]);
  const [shopOptions, setOptions] = useState([]);
  const [productTypeOptions, setProductTypeOptions] = useState([]);
  const [stepRoutineOptions, setStepRoutineOptions] = useState([]);

  // const [themeColor, setThemeColor] = useState("");
  const [image, setImage] = useState(null);
  const [thumbHowTo, setThumbHowTo] = useState(null);
  const [hoverThumb, setHoverThumb] = useState(null);

  const howToImage = useRef(null);
  const uploaderRef = useRef(null);
  const selectedImage = useRef(null);
  const uploadHoverRef = useRef(null);

  // hooks
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { fields, remove, append } = useFieldArray({
    control,
    name: "shops",
  });

  // api calling
  const { data: routineData, isLoading: routineLoading, isSuccess: routineSuccess, isError: routineError } = useGetRoutine();
  const { data: prodTypeData, isLoading: prodTypeLoading, isSuccess: prodTypeSuccess, isError: prodTypeError } = useGetProductTypes();
  const { data: categoriesData, isLoading: loadingCategory, isSuccess: successCategory, isError: isCategoriesError } = useGetProductCategories();
  const { data: productData, isLoading: loadingProduct, isSuccess: successProduct, isError: isProductError } = useGetProducts();
  const { data: fragranceData, isLoading: loadingFragrance, isSuccess: successFragrance, isError: isFragranceError } = useGetFragrance();
  const { data: shopData, isLoading: loadingShop, isSuccess: successShop, isError: isShopError } = useGetShop();

  // query
  const { isLoading: createLoading, mutate: crateMutate } = useMutation(async (data) => await ProductApi.Add(data), {
    onSettled: (response, error) => {
      if (error) {
        return toast.error("an error occured", { duration: 5000 });
      }

      if (response.data.status === 200) {
        navigate("/dashboard/products");
        return;
      } else {
        toast.error(response.data.message, { duration: 5000 });
      }
    },
  });

  // functions
  const onSubmit = (data) => {
    const formData = new FormData();
    // override status value with boolean

    data.active_status = Boolean(data.active_status);

    // images
    let files = uploaderRef?.current?.files;
    let selectedFile = selectedImage?.current?.files[0];
    let howToFile = howToImage?.current?.files[0];
    let hoverFile = uploadHoverRef?.current?.files[0];

    // make image required
    if (!howToFile) return toast.error("How To image Required", { duration: 6000 });
    formData.append("how_to_files", howToFile);

    if (!hoverFile) return toast.error("Product Hover Image Required", { duration: 6000 });
    formData.append("background_files", hoverFile);

    if (selectedFile) {
      formData.append("files2", selectedFile);
    }

    if (!files || !files.length) return toast.error("Image Required", { duration: 6000 });

    for (let i = 0; i < files.length; i++) {
      formData.append("files", files[i]);
    }

    // remove undifined inside data
    Object.keys(data).forEach((key) => {
      const isUndifined = data[key] === undefined;
      if (isUndifined) {
        delete data[key];
      }
    });

    // looping object to form data
    Object.keys(data).forEach((key) => {
      let isArray = Array.isArray(data[key]);

      // if array loop with same key
      if (isArray) {
        let isArrOfObj = isArrayOfObj(data[key]);

        if (isArrOfObj) {
          for (let i = 0; i < data[key].length; i++) {
            let item = data[key];
            formData.append(key, JSON.stringify(item[i]));
          }
        } else {
          for (let i = 0; i < data[key].length; i++) {
            let item = data[key];
            formData.append(key, item[i]);
          }
        }
      } else {
        // to prevent undifined value
        if (data[key]) {
          formData.append(key, data[key]);
        }
      }
    });

    // formDataDebugger(formData);
    crateMutate(formData);
  };

  const selectImage = async (e) => {
    let selectedFile = e.target.files[0];
    let createBlob = URL.createObjectURL(selectedFile);
    setImage(createBlob);
  };

  const removeImage = () => {
    let fileInput = document.getElementById("upload-single");
    fileInput.value = null;

    setImage(null);
  };

  const howToSelectImage = async (e) => {
    let selectedFile = e.target.files[0];
    let createBlob = URL.createObjectURL(selectedFile);
    setThumbHowTo(createBlob);
  };

  const hoverImageUpload = async (e) => {
    let selectedFile = e.target.files[0];
    let createBlob = URL.createObjectURL(selectedFile);
    setHoverThumb(createBlob);
  };

  const removeHowToImage = () => {
    let fileInput = document.getElementById("upload-how-to");
    fileInput.value = null;
    setThumbHowTo(null);
  };

  const removeHoverImage = () => {
    let fileInput = document.getElementById("hover-image");
    fileInput.value = null;

    setHoverThumb(null);
  };

  // components
  const leftToolbar = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <h4 className="uppercase" style={{ margin: 0 }}>
            Create Product
          </h4>
        </div>
      </React.Fragment>
    );
  };

  const isArrayOfObj = (array) => {
    return array.some((data) => data.route);
  };

  const rightToolbar = () => {
    return (
      <React.Fragment>
        <div>
          <label htmlFor="status" className="mr-2">
            Publish Product :
          </label>
          <Controller
            rules={{ required: true }}
            control={control}
            name="active_status"
            render={({ field }) => <Dropdown onBlur={field.onBlur} id="status" ref={field.ref} value={field.value} className={errors.active_status && "p-invalid"} onChange={(e) => field.onChange(e)} options={publishStatus} optionLabel="name" placeholder="Product Status" />}
          />
        </div>
        <Link to="/dashboard/products">
          <Button label="Back" className="p-button-outlined p-button-secondary ml-4" />
        </Link>
      </React.Fragment>
    );
  };

  const emptyTemplate = () => {
    return (
      <div className="flex align-items-center flex-column">
        <i className="pi pi-image mt-3 p-5" style={{ fontSize: "5em", borderRadius: "50%", backgroundColor: "var(--surface-b)", color: "var(--surface-d)" }}></i>
        <span style={{ fontSize: "1.2em", color: "var(--text-color-secondary)" }} className="my-5">
          Drag and Drop Image Here
        </span>
      </div>
    );
  };

  const CurrentImages = () => {
    return image ? (
      <div className="mt-4">
        <div className="grid">
          <div className="col-12 md:col-6 lg:col-3 relative flex justify-content-center align-items-center ">
            <img className="w-full" style={{ objectFit: "contain" }} height="200px" src={image} alt="" />
            <Button type="button" onClick={removeImage} icon="pi pi-times" className="p-button-rounded p-button-danger mr-2 mb-2 absolute" />
          </div>
        </div>
      </div>
    ) : (
      ""
    );
  };

  const HowToSelectedImage = () => {
    return thumbHowTo ? (
      <div className="mt-4">
        <div className="grid">
          <div className="col-12 md:col-6 lg:col-3 relative flex justify-content-center align-items-center ">
            <img className="w-full" style={{ objectFit: "contain" }} height="200px" src={thumbHowTo} alt="" />
            <Button type="button" onClick={removeHowToImage} icon="pi pi-times" className="p-button-rounded p-button-danger mr-2 mb-2 absolute" />
          </div>
        </div>
      </div>
    ) : (
      ""
    );
  };

  const HoverImage = () => {
    return hoverThumb ? (
      <div className="mt-4">
        <div className="grid">
          <div className="col-12 md:col-6 lg:col-3 relative flex justify-content-center align-items-center ">
            <img className="w-full" style={{ objectFit: "contain" }} height="200px" src={hoverThumb} alt="" />
            <Button type="button" onClick={removeHoverImage} icon="pi pi-times" className="p-button-rounded p-button-danger mr-2 mb-2 absolute" />
          </div>
        </div>
      </div>
    ) : (
      ""
    );
  };

  const fileUploadHeaderTemplate = (options) => {
    const { className, chooseButton } = options;
    return (
      <div className={className} style={{ backgroundColor: "transparent", display: "flex", alignItems: "center" }}>
        {chooseButton}
      </div>
    );
  };

  // lifecycle
  useEffect(() => {
    let temp_data = [];

    if (isCategoriesError) {
      toast.error("Please check your connections", { duration: 6000 });
      return;
    }

    if (!loadingCategory && successCategory) {
      for (let i = 0; i < categoriesData.length; i++) {
        temp_data.push({ name: categoriesData[i].name, value: categoriesData[i]._id });
      }
    }

    setCategoryOption(temp_data);
  }, [loadingCategory, successCategory, categoriesData, isCategoriesError]);

  useEffect(() => {
    let temp_data = [];

    if (routineError) {
      toast.error("Please check your connections", { duration: 6000 });
      return;
    }

    if (!routineLoading && routineSuccess) {
      for (let i = 0; i < routineData.length; i++) {
        temp_data.push({ name: routineData[i].name, value: routineData[i]._id });
      }
    }

    setStepRoutineOptions(temp_data);
  }, [routineLoading, routineSuccess, routineData, routineError]);

  useEffect(() => {
    let temp_data = [];

    if (prodTypeError) {
      toast.error("Please check your connections", { duration: 6000 });
      return;
    }

    if (!prodTypeLoading && prodTypeSuccess) {
      for (let i = 0; i < prodTypeData.length; i++) {
        temp_data.push({ name: prodTypeData[i].type, value: prodTypeData[i]._id });
      }
    }

    setProductTypeOptions(temp_data);
  }, [prodTypeLoading, prodTypeSuccess, prodTypeData, prodTypeError]);

  useEffect(() => {
    let temp_data = [];

    if (isProductError) {
      toast.error("Please check your connections", { duration: 6000 });
      return;
    }

    if (!loadingProduct && successProduct) {
      for (let i = 0; i < productData.length; i++) {
        temp_data.push({ name: productData[i].name, value: productData[i]._id });
      }
    }

    setProductOptions(temp_data);
  }, [loadingProduct, successProduct, productData, isProductError]);

  useEffect(() => {
    let temp_data = [];

    if (isFragranceError) {
      toast.error("Please check your connections", { duration: 6000 });
      return;
    }

    if (!loadingFragrance && successFragrance) {
      for (let i = 0; i < fragranceData.length; i++) {
        temp_data.push({ name: fragranceData[i].name, value: fragranceData[i]._id });
      }
    }

    setFragranceOptions(temp_data);
  }, [loadingFragrance, successFragrance, fragranceData, isFragranceError]);

  useEffect(() => {
    let temp_data = [];

    if (isShopError) {
      toast.error("Please check your connections", { duration: 6000 });
      return;
    }

    if (!loadingShop && successShop) {
      for (let i = 0; i < shopData.length; i++) {
        temp_data.push({ name: shopData[i].name, value: shopData[i]._id });
      }
    }

    setOptions(temp_data);
  }, [loadingShop, successShop, shopData, isShopError]);

  useEffect(() => {
    append();
  }, [append]);

  return (
    <>
      <Toaster />
      <form onSubmit={handleSubmit(onSubmit)} className="card">
        <Toolbar className="mb-4" left={leftToolbar} right={rightToolbar} />
        <div>
          <div className="field col-12 md:col-12">
            <div className="p-fluid formgrid grid ">
              <div className="field col-12 md:col-6">
                <label htmlFor="user">Product Name :</label>
                <InputText placeholder="Input product name" {...register("name", { required: true })} id="name" type="text" className={errors.name && "p-invalid"} />
                {errors.name && (
                  <small id="name-help" className="p-error block">
                    Name is required
                  </small>
                )}
              </div>
              <div className="field col-12 md:col-3">
                <label htmlFor="category_id">Product Type : </label>
                <Controller
                  rules={{ required: true }}
                  control={control}
                  name="product_type_id"
                  render={({ field }) => <Dropdown onBlur={field.onBlur} id="status" ref={field.ref} value={field.value} className={errors.product_type_id && "p-invalid"} onChange={(e) => field.onChange(e)} options={productTypeOptions} optionLabel="name" placeholder="Product Status" />}
                />

                {errors.product_type_id && (
                  <small id="name-help" className="p-error block">
                    At least please select one
                  </small>
                )}
              </div>
              <div className="field col-12 md:col-3">
                <label htmlFor="category_id">Category : </label>
                <Controller
                  rules={{ required: true }}
                  control={control}
                  name="category_id"
                  render={({ field }) => (
                    <MultiSelect
                      onBlur={field.onBlur}
                      ref={field.ref}
                      id="category_id"
                      value={field.value}
                      onChange={(e) => field.onChange(e)}
                      className={errors.category_id && "p-invalid"}
                      disabled={!categoryOptions.length}
                      options={categoryOptions}
                      optionLabel="name"
                      placeholder="Select product category"
                    />
                  )}
                />

                {errors.category_id && (
                  <small id="name-help" className="p-error block">
                    At least please select one
                  </small>
                )}
              </div>
              <div className="field col-12 md:col-3">
                <label htmlFor="related">Related Product : </label>
                <Controller
                  rules={{ required: false }}
                  control={control}
                  name="related"
                  render={({ field }) => <MultiSelect onBlur={field.onBlur} id="related" value={field.value} onChange={(e) => field.onChange(e)} options={productOptions} optionLabel="name" disabled={!productOptions.length} placeholder="Select product (optional)" />}
                />
              </div>
              <div className="field col-12 md:col-2">
                <label htmlFor="user">Weight : (ML)</label>
                <Controller
                  rules={{ required: true }}
                  control={control}
                  name="weight"
                  render={({ field }) => <InputNumber onBlur={field.onBlur} ref={field.ref} value={field.value} onValueChange={(e) => field.onChange(e)} showButtons mode="decimal" placeholder="0" className={errors.weight && "p-invalid"} />}
                />
                {errors.weight && (
                  <small id="name-help" className="p-error block">
                    Weight is required
                  </small>
                )}
              </div>
              <div className="field col-12 md:col-2">
                <label htmlFor="user">Qty :</label>
                <Controller
                  rules={{ required: true }}
                  control={control}
                  name="qty"
                  render={({ field }) => <InputNumber onBlur={field.onBlur} ref={field.ref} value={field.value} onValueChange={(e) => field.onChange(e)} showButtons mode="decimal" placeholder="0" className={errors.qty && "p-invalid"} />}
                />
                {errors.qty && (
                  <small id="name-help" className="p-error block">
                    Qty is required
                  </small>
                )}
              </div>
              <div className="field col-12 md:col-2">
                <label htmlFor="user">Price :</label>
                <Controller
                  rules={{ required: true }}
                  control={control}
                  name="price"
                  render={({ field }) => <InputNumber onBlur={field.onBlur} ref={field.ref} value={field.value} onValueChange={(e) => field.onChange(e)} showButtons mode="decimal" placeholder="0" className={errors.price && "p-invalid"} />}
                />
                {errors.price && (
                  <small id="name-help" className="p-error block">
                    Price is required
                  </small>
                )}
              </div>
              <div className="field col-12 md:col-12">
                <div className="field p-fluid">
                  <label>Product Description :</label>
                  <InputTextarea {...register("description", { required: true })} className={errors.description && "p-invalid"} placeholder="Write product description" autoResize rows="10" cols="30" />
                </div>
                {/* How to use image */}
                <div className="field col-12 md:col-12">
                  <div className="field p-fluid">
                    <label>Product Hover Image :</label>
                    <div className="">
                      <input ref={uploadHoverRef} id="hover-image" onChange={hoverImageUpload} accept="image/*" type="file" />
                    </div>
                  </div>
                  <div className="">
                    <HoverImage />
                  </div>
                </div>
                {/* <div className="field">
                  <div className="mb-2 flex align-content-center align-items-center">
                    <span className="" htmlFor="user">
                      Product Background Theme :
                    </span>
                    <div className="ml-2" style={{ height: "15px", width: "15px", backgroundColor: themeColor }}></div>
                  </div>
                  <Controller
                    rules={{ required: true }}
                    control={control}
                    name="background_color"
                    defaultValue="#FFFF"
                    render={({ field }) => (
                      <TwitterPicker
                        color={field.value}
                        onBlur={field.onBlur}
                        ref={field.ref}
                        onChange={(e) => {
                          field.onChange(e.hex);
                          setThemeColor(e.hex);
                        }}
                      />
                    )}
                  />
                </div> */}
              </div>
            </div>
          </div>

          <div className="field col-12 md:col-12">
            <div>
              <label>Product Images :</label>
              <FileUpload ref={uploaderRef} className="mt-2" name="demo[]" customUpload u headerTemplate={fileUploadHeaderTemplate} multiple accept="image/*" maxFileSize={10000000} emptyTemplate={emptyTemplate} />
            </div>
          </div>

          {/* superlative_claim */}
          <div className="field col-12 md:col-12 mt-4">
            <div className="field p-fluid">
              <label>Product Superlative Claim : {errors.superlative_claim && <span style={{ color: "#D9362B" }}>Content Required</span>}</label>
              <Controller rules={{ required: true }} control={control} name="superlative_claim" render={({ field }) => <ReactQuill className="custom-quill" onBlur={field.onBlur} ref={field.ref} modules={reactQuill.modules} value={field.value} onChange={(e) => field.onChange(e)} theme="snow" />} />
            </div>
          </div>
          {/* benefit */}
          <div className="field col-12 md:col-12 mt-4">
            <div className="field p-fluid">
              <label>Product Benefit : {errors.content && <span style={{ color: "#D9362B" }}>Content Required</span>}</label>
              <Controller rules={{ required: true }} control={control} name="benefit" render={({ field }) => <ReactQuill className="custom-quill" onBlur={field.onBlur} ref={field.ref} modules={reactQuill.modules} value={field.value} onChange={(e) => field.onChange(e)} theme="snow" />} />
            </div>
          </div>

          {/* product results */}
          <div className="field col-12 md:col-12">
            <div className="field p-fluid">
              <label>Product Ingredient : {errors.content && <span style={{ color: "#D9362B" }}>Content Required</span>}</label>
              <Controller rules={{ required: true }} control={control} name="ingredient" render={({ field }) => <ReactQuill className="custom-quill" onBlur={field.onBlur} ref={field.ref} modules={reactQuill.modules} value={field.value} onChange={(e) => field.onChange(e)} theme="snow" />} />
            </div>
          </div>

          {/* product ingridients */}
          <div className="field col-12 md:col-12">
            <div className="field p-fluid">
              <label>Product Result test : {errors.content && <span style={{ color: "#D9362B" }}>Content Required</span>}</label>
              <Controller rules={{ required: true }} control={control} name="result_test" render={({ field }) => <ReactQuill className="custom-quill" onBlur={field.onBlur} ref={field.ref} modules={reactQuill.modules} value={field.value} onChange={(e) => field.onChange(e)} theme="snow" />} />
            </div>
          </div>

          <div className="field col-12 md:col-12">
            <div className="field p-fluid">
              <label>Product How To : {errors.content && <span style={{ color: "#D9362B" }}>Content Required</span>}</label>
              <Controller rules={{ required: true }} control={control} name="how_to" render={({ field }) => <ReactQuill className="custom-quill" onBlur={field.onBlur} ref={field.ref} modules={reactQuill.modules} value={field.value} onChange={(e) => field.onChange(e)} theme="snow" />} />
            </div>
          </div>

          {/* How to use image */}
          <div className="field col-12 md:col-12">
            <div className="field p-fluid">
              <label>How to use image :</label>
              <div className="">
                <input ref={howToImage} id="upload-how-to" onChange={howToSelectImage} accept="image/*" type="file" />
              </div>
            </div>
            <div className="">
              <HowToSelectedImage />
            </div>
          </div>

          {/* top notes side image */}
          <div className="field col-12 md:col-12">
            <div className="field p-fluid">
              <label>Top Notes Side Image :</label>
              <div className="">
                <input ref={selectedImage} id="upload-single" onChange={selectImage} accept="image/*" type="file" />
              </div>
            </div>
            <div className="">
              <CurrentImages />
            </div>
          </div>

          {/* top notes */}
          <div className="p-fluid formgrid grid mt-4">
            <div className="field col-12 md:col-4">
              <div className="field p-fluid">
                <label>Top Notes : {errors.content && <span style={{ color: "#D9362B" }}>Content Required</span>}</label>
                <Controller
                  // rules={{ required: true }}
                  control={control}
                  name="top_notes"
                  render={({ field }) => (
                    <MultiSelect
                      onBlur={field.onBlur}
                      ref={field.ref}
                      id="top_notes"
                      value={field.value}
                      onChange={(e) => field.onChange(e)}
                      className={errors.top_notes && "p-invalid"}
                      disabled={!fragranceOptions.length}
                      options={fragranceOptions}
                      optionLabel="name"
                      placeholder="Select Top Notes Fragrance"
                    />
                  )}
                />
              </div>
            </div>
            <div className="field col-12 md:col-4">
              <div className="field p-fluid">
                <label>Middle Notes : {errors.middle_notes && <span style={{ color: "#D9362B" }}>Content Required</span>}</label>
                <Controller
                  // rules={{ required: true }}
                  control={control}
                  name="middle_notes"
                  render={({ field }) => (
                    <MultiSelect
                      onBlur={field.onBlur}
                      ref={field.ref}
                      id="middle_notes"
                      value={field.value}
                      onChange={(e) => field.onChange(e)}
                      className={errors.middle_notes && "p-invalid"}
                      disabled={!fragranceOptions.length}
                      options={fragranceOptions}
                      optionLabel="name"
                      placeholder="Select Middle Notes Fragrance"
                    />
                  )}
                />
              </div>
            </div>
            <div className="field col-12 md:col-4">
              <div className="field p-fluid">
                <label>Dry Down : {errors.content && <span style={{ color: "#D9362B" }}>Content Required</span>}</label>
                <Controller
                  // rules={{ required: true }}
                  control={control}
                  name="dry_down"
                  render={({ field }) => (
                    <MultiSelect
                      onBlur={field.onBlur}
                      ref={field.ref}
                      id="dry_down"
                      value={field.value}
                      onChange={(e) => field.onChange(e)}
                      className={errors.dry_down && "p-invalid"}
                      disabled={!fragranceOptions.length}
                      options={fragranceOptions}
                      optionLabel="name"
                      placeholder="Select Dry Down Fragrance"
                    />
                  )}
                />
              </div>
            </div>

            <div className="field col-12 md:col-4">
              <div className="field p-fluid">
                <label>Step Routine (AM) :</label>
                <Controller
                  // rules={{ required: true }}
                  control={control}
                  name="steps"
                  render={({ field }) => (
                    <MultiSelect
                      onBlur={field.onBlur}
                      ref={field.ref}
                      id="steps"
                      value={field.value}
                      onChange={(e) => field.onChange(e)}
                      className={errors.steps && "p-invalid"}
                      disabled={!stepRoutineOptions.length}
                      options={stepRoutineOptions}
                      optionLabel="name"
                      placeholder="Select Step Routine (AM)"
                    />
                  )}
                />
              </div>
            </div>
            <div className="field col-12 md:col-4">
              <div className="field p-fluid">
                <label>Step Routine (PM) :</label>
                <Controller
                  // rules={{ required: true }}
                  control={control}
                  name="steps2"
                  render={({ field }) => (
                    <MultiSelect
                      onBlur={field.onBlur}
                      ref={field.ref}
                      id="steps"
                      value={field.value}
                      onChange={(e) => field.onChange(e)}
                      className={errors.steps && "p-invalid"}
                      disabled={!stepRoutineOptions.length}
                      options={stepRoutineOptions}
                      optionLabel="name"
                      placeholder="Select Step Routine (PM)"
                    />
                  )}
                />
              </div>
            </div>
          </div>

          <p>Product Status Please choose one : </p>
          <div className="p-fluid formgrid grid">
            <div className="field col-12 md:col-2">
              <label htmlFor="user">New Arrival:</label>
              <div className="">
                <Controller control={control} name="new_arrival" render={({ field }) => <InputSwitch onChange={(e) => field.onChange(e)} checked={field.value} />} />
              </div>
            </div>
            <div className="field col-12 md:col-2">
              <label htmlFor="user">Best Seller:</label>
              <div className="">
                <Controller control={control} name="best_seller" render={({ field }) => <InputSwitch onChange={(e) => field.onChange(e)} checked={field.value} />} />
              </div>
            </div>
          </div>

          {/* external e-commerce link */}
          <div className="field col-12 md:col-12 mt-4">
            <div className="field p-fluid">
              <label>Other E-Commerce Link :</label>
              <div className="">
                {fields.map((field, index) => {
                  return (
                    <div key={field.id} className="p-fluid formgrid grid">
                      <div className="field col-12 md:col-6">
                        {/* <label>Link</label> */}
                        <InputText placeholder="Product link example : https://toko..." {...register(`shops.${index}.route`, { required: true })} id="name" type="text" />
                      </div>
                      <div className="field col-12 md:col-5">
                        {/* <label>Platform</label> */}
                        <Controller
                          rules={{ required: true }}
                          control={control}
                          name={`shops.${index}.shop_id`}
                          render={({ field }) => <Dropdown onBlur={field.onBlur} id="status" ref={field.ref} value={field.value} onChange={(e) => field.onChange(e)} options={shopOptions} optionLabel="name" placeholder="Choose Platform" />}
                        />
                      </div>
                      <div className="field col-12 md:col-1">
                        {/* <label>Action</label> */}
                        <Button type="button" onClick={() => remove(index)} label="x" className="p-button-danger mr-4" />
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="flex justify-content-left">
                <div className=" col-12 md:col-2">
                  <Button type="button" onClick={() => append()} label="Add +" className=" p-button-primary mr-4" />
                </div>
              </div>
            </div>
          </div>

          {/* buttons */}
          <div className="flex justify-content-center mt-4">
            <Button label="Save" loading={createLoading} className=" p-button-primary mr-4" />
            <Link to="/dashboard/products">
              <Button type="button" label="Back" className=" p-button-secondary" />
            </Link>
          </div>
        </div>
      </form>
      {/* <DevTool control={control} /> */}
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location?.path === nextProps.location?.path;
};

export default React.memo(CreateProduct, comparisonFn);
