// import Dashboard from "./pages/Dashboard";
import Users from "./pages/Users/Users";
import Blog from "./pages/Blog/Blog";
import UpdateBlog from "./pages/Blog/UpdateBlog";
import CreateBlog from "./pages/Blog/CreateBlog";
// import UserAccount from "./pages/UserAccount";
import Product from "./pages/Product/Product";
import CreateProduct from "./pages/Product/CreateProduct";
import UpdateProduct from "./pages/Product/UpdateProduct";
import CategoriesProduct from "./pages/ProductCategories/CategoriesProduct";
import BlogCategories from "./pages/Blog/BlogCategories";
import Role from "./pages/Role/Role";
import Page from "./pages/PageSetting/Page";
import CreatePage from "./pages/PageSetting/CreatePage";
import CreateRole from "./pages/Role/CreateRole";
import UpdateRole from "./pages/Role/UpdateRole";
import Fragrance from "./pages/Fragrance/Fragrance";
import Shop from "./pages/Shop/Shop";
import Claims from "./pages/Claims/Claims";
import Quiz from "./pages/Quiz/Quiz";
import QuizQuestions from "./pages/Quiz/QuizQuestions";
import Banners from "./pages/Home/Banners";
import Ingridients from "./pages/Home/Ingridients";
import Reviews from "./pages/Home/Reviews";
import HomeSetting from "./pages/HomeSetting";
import ProductTypes from "./pages/ProductTypes";
import StepRoutine from "./pages/StepRoutine";
import ProductReviews from "./pages/ProductReviews";
import FaqSetting from "./pages/FaqSetting";
import AboutPage from "./pages/AboutPage";
import GlossaryItemPage from "./pages/GlossaryItemPage";
import GlossaryAddPage from "./pages/GlossaryAddPage";
import GlossarySetting from "./pages/GlossarySetting";
import HeaderSetting from "./pages/HeaderSetting";
import JoinGlowPage from "./pages/JoinGlowPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import TermConditionPage from "./pages/TermConditionPage";
import SitemapPage from "./pages/Sitemap/SitemapPage";
import CreateSitemap from "./pages/Sitemap/CreateSitemap";
import UpdateSitemap from "./pages/Sitemap/UpdateSitemap";
import GlowQueenForm from "./pages/GlowQueenForm/GlowQueenForm";

//   {
//     route: "UserAccount",
//     component: UserAccount,
//   },

const pagesList = [
  {
    route: "/dashboard/glow-queen-form",
    component: GlowQueenForm,
    childs: [],
    hasChild: false,
  },
  {
    route: "/dashboard/products",
    component: Product,
    childs: [
      {
        route: "/dashboard/products/create",
        component: CreateProduct,
        access: "create",
      },
      {
        route: "/dashboard/products/update/:id",
        component: UpdateProduct,
        access: "update",
      },
    ],
    hasChild: true,
  },

  {
    route: "/dashboard/blog",
    component: Blog,
    childs: [
      {
        route: "/dashboard/blog/create",
        component: CreateBlog,
        access: "create",
      },
      {
        route: "/dashboard/blog/update/:id",
        component: UpdateBlog,
        access: "update",
      },
    ],
    hasChild: true,
  },
  {
    route: "/dashboard/sitemap",
    component: SitemapPage,
    childs: [
      {
        route: "/dashboard/sitemap/create",
        component: CreateSitemap,
        access: "create",
      },
      {
        route: "/dashboard/sitemap/update/:id",
        component: UpdateSitemap,
        access: "update",
      },
    ],
    hasChild: true,
  },
  {
    route: "/dashboard/role",
    component: Role,
    childs: [
      {
        route: "/dashboard/role/create",
        component: CreateRole,
        access: "create",
      },
      {
        route: "/dashboard/role/update/:id",
        component: UpdateRole,
        access: "update",
      },
    ],
    hasChild: true,
  },
  {
    route: "/dashboard/pages",
    component: Page,
    childs: [
      {
        route: "/dashboard/pages/create",
        component: CreatePage,
        access: "create",
      },
    ],
    hasChild: true,
  },
  {
    route: "/dashboard/glossary-list",
    component: GlossaryItemPage,
    childs: [
      {
        route: "/dashboard/glossary-list/add",
        component: GlossaryAddPage,
        access: "create",
      },
    ],
    hasChild: true,
  },
  {
    route: "/dashboard/product-types",
    component: ProductTypes,
    childs: [],
    hasChild: false,
  },
  {
    route: "/dashboard/users",
    component: Users,
    childs: [],
    hasChild: false,
  },

  {
    route: "/dashboard/products-categories",
    component: CategoriesProduct,
    childs: [],
    hasChild: false,
  },
  {
    route: "/dashboard/blog-categories",
    component: BlogCategories,
    childs: [],
    hasChild: false,
  },

  {
    route: "/dashboard/fragrance",
    component: Fragrance,
    childs: [],
    hasChild: false,
  },
  {
    route: "/dashboard/shop",
    component: Shop,
    childs: [],
    hasChild: false,
  },
  {
    route: "/dashboard/claims",
    component: Claims,
    childs: [],
    hasChild: false,
  },
  {
    route: "/dashboard/quiz",
    component: Quiz,
    childs: [],
    hasChild: false,
  },
  {
    route: "/dashboard/quiz-questions",
    component: QuizQuestions,
    childs: [],
    hasChild: false,
  },
  {
    route: "/dashboard/banners",
    component: Banners,
    childs: [],
    hasChild: false,
  },
  {
    route: "/dashboard/ingridients",
    component: Ingridients,
    childs: [],
    hasChild: false,
  },
  {
    route: "/dashboard/reviews",
    component: Reviews,
    childs: [],
    hasChild: false,
  },
  {
    route: "/dashboard/home-setting",
    component: HomeSetting,
    childs: [],
    hasChild: false,
  },
  {
    route: "/dashboard/step-routine",
    component: StepRoutine,
    childs: [],
    hasChild: false,
  },
  {
    route: "/dashboard/product-reviews",
    component: ProductReviews,
    childs: [],
    hasChild: false,
  },
  {
    route: "/dashboard/faq-setting",
    component: FaqSetting,
    childs: [],
    hasChild: false,
  },
  {
    route: "/dashboard/about-setting",
    component: AboutPage,
    childs: [],
    hasChild: false,
  },

  {
    route: "/dashboard/glossary-setting",
    component: GlossarySetting,
    childs: [],
    hasChild: false,
  },
  {
    route: "/dashboard/header-setting",
    component: HeaderSetting,
    childs: [],
    hasChild: false,
  },
  {
    route: "/dashboard/join-glow-queens",
    component: JoinGlowPage,
    childs: [],
    hasChild: false,
  },
  {
    route: "/dashboard/privacy-setting",
    component: PrivacyPolicyPage,
    childs: [],
    hasChild: false,
  },
  {
    route: "/dashboard/terms-setting",
    component: TermConditionPage,
    childs: [],
    hasChild: false,
  },
];

export default pagesList;
