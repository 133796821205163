import Api from "./Api";

export default {
  Get() {
    return Api().get("/home/banner");
  },
  Update(data) {
    return Api().post(`/home/banner/edit`, data);
  },
  Details(id) {
    return Api().get(`/home/banner/${id}`);
  },
  Add(data) {
    return Api().post(`/home/banner`, data);
  },
  Delete(data) {
    return Api().delete(`/home/banner`, { data: data });
  },
};
