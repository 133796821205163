import Api from "./Api";

export default {
  Get() {
    return Api().get("/user");
  },
  Export(data) {
    return Api().post("/user/export", data);
  },
  Update(data) {
    return Api().post(`/user/edit`, data);
  },
  Login(data) {
    return Api().post(`/user/login`, data);
  },
  Details(id) {
    return Api().get(`/user/${id}`);
  },
  Add(data) {
    return Api().post(`/user`, data);
  },
  Delete(data) {
    return Api().delete(`/user`, { data: data });
  },
};
