// hooks
import React, { useEffect, useState } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { useQuery, useMutation } from "react-query";

// conponents
import { InputText } from "primereact/inputtext";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { Link } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";

// api related
import ReactQuill from "react-quill";
import reactQuill from "../config/reactQuill";
import "react-quill/dist/quill.snow.css";
import Api from "../api/Api";

const FaqSetting = ({ permissions }) => {
  const [id, setId] = useState(null);
  // hooks
  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const { fields, remove, append } = useFieldArray({
    control,
    name: "section1_tabs",
  });

  const { isLoading, refetch } = useQuery("faq-data", async () => await getDetails(), { refetchOnWindowFocus: false });
  const { isLoading: loadingUpdate, mutate: updateData } = useMutation(async (data) => Api().post("faq/page/edit", data), {
    onSettled: (response, error) => {
      if (error) {
        toast.error("An Error Occured!");
        return;
      }

      if (response.data.status !== 200) {
        toast.error(response.data.message);
        return;
      }
      refetch();
      toast.success("Faq Updated");
    },
  });

  // functions
  const getDetails = async () => {
    try {
      const response = await Api().get(`/faq/detail`);

      if (response.data.status !== 200) {
        toast.error("Failed to get data");
        throw new Error("Error");
      }

      setId(response.data.data._id);
      reset(response.data.data);
      return response.data.data;
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmit = (data) => {
    updateData({ ...data, faq_id: id });
  };

  // components
  const leftToolbar = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <h4 className="uppercase" style={{ margin: 0 }}>
            Faq Setting
          </h4>
        </div>
      </React.Fragment>
    );
  };

  const NestedField = ({ parentIndex, control }) => {
    const {
      fields: childFields,
      remove: removeChild,
      append: appendChild,
      insert,
    } = useFieldArray({
      control,
      name: `section1_tabs[${parentIndex}].questions`,
    });

    // useEffect(() => {
    //   getDetails();
    // }, []);

    return (
      <>
        {childFields.map((fields, childIndex) => {
          return (
            <div key={fields.id} className="field col-12 formgrid grid">
              <div className="field col-1 flex justify-content-center align-items-center" style={{ height: "100%" }}>
                <div className="" style={{ height: "85%", width: "2px", border: "2px dotted black" }}></div>
              </div>
              <div className="field col-11 formgrid grid">
                <div className="field md:col-5">
                  <label htmlFor="user">Question :</label>
                  <Controller
                    rules={{ required: true }}
                    control={control}
                    defaultValue={""}
                    name={`section1_tabs[${parentIndex}].questions[${childIndex}].question`}
                    render={({ field }) => <InputText placeholder="Input product name" value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className={errors.name && "p-invalid"} />}
                  />
                </div>
                <div className="field md:col-2">
                  <label htmlFor="user" style={{ visibility: "hidden" }}>
                    Insert Below This Row:
                  </label>
                  <Button type="button" onClick={() => insert(childIndex + 1, {})} label="Insert Below" className="p-button-primary mr-4" />
                </div>
                <div className="field md:col-1">
                  <label htmlFor="user" style={{ visibility: "hidden" }}>
                    'Remove':
                  </label>
                  <Button type="button" onClick={() => removeChild(childIndex)} label="X" className="p-button-danger mr-4" />
                </div>
                <div className="field md:col-12">
                  <label htmlFor="user">Answer :</label>
                  <Controller
                    rules={{ required: true }}
                    control={control}
                    defaultValue={""}
                    name={`section1_tabs[${parentIndex}].questions[${childIndex}].answer`}
                    render={({ field }) => <ReactQuill className="custom-quill" onBlur={field.onBlur} ref={field.ref} modules={reactQuill.modules} value={field.value} onChange={(e) => field.onChange(e)} theme="snow" />}
                  />
                </div>
              </div>
            </div>
          );
        })}

        <div className="field col-12 md:col-1"></div>
        <div className="field col-12 md:col-2">
          <Button
            type="button"
            onClick={() =>
              appendChild({
                question: "",
                answer: "",
              })
            }
            label="Add Question"
            className="p-button-primary mr-4"
          />
        </div>
      </>
    );
  };

  // lifecycle
  useEffect(() => {
    append();
  }, [append]);

  return (
    <>
      <Toaster />
      <form onSubmit={handleSubmit(onSubmit)} className="card">
        <Toolbar className="mb-4" left={leftToolbar} />
        <div className="p-fluid formgrid grid justify-content-center">
          <div className="field col-12">
            <div className="field">
              <label htmlFor="user">Title :</label>
              <Controller
                rules={{ required: true }}
                control={control}
                defaultValue={""}
                name="section1_title"
                render={({ field }) => <InputText placeholder="Input Tab name" value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className={errors.name && "p-invalid"} />}
              />

              {errors.section1_title && (
                <small id="name-help" className="p-error block">
                  Name is required
                </small>
              )}
            </div>
          </div>

          {fields.map((fields, parentIndex) => {
            return (
              <div key={fields.id} className="field col-12 formgrid grid ">
                <div className="field md:col-8">
                  <label htmlFor="user">Tab {parentIndex + 1} Title :</label>
                  <Controller
                    rules={{ required: true }}
                    control={control}
                    defaultValue={""}
                    name={`section1_tabs[${parentIndex}].title`}
                    render={({ field }) => <InputText placeholder="Tab Name" value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" />}
                  />
                </div>
                <div className="field md:col-2">
                  <label htmlFor="user">Action :</label>
                  <Button type="button" onClick={() => remove(parentIndex)} label="Remove Tab" className="p-button-danger mr-4" />
                </div>
                <NestedField parentIndex={parentIndex} {...{ control, register }} />
              </div>
            );
          })}
          <div className="field col-12 md:col-4">
            <Button type="button" onClick={() => append()} label="Add Tab" className="p-button-primary mr-4" />
          </div>
        </div>
        <div className="flex justify-content-center mt-4">
          {permissions.update && <Button disabled={isLoading} loading={loadingUpdate} label="Save" className=" p-button-primary mr-4" />}
          <Link to="/dashboard/products">
            <Button type="button" label="Back" className=" p-button-secondary" />
          </Link>
        </div>
      </form>
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location?.path === nextProps.location?.path;
};

export default React.memo(FaqSetting, comparisonFn);
