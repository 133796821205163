// hooks
import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useDebounce } from "use-debounce";

// components
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import toast, { Toaster } from "react-hot-toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { InputSwitch } from "primereact/inputswitch";
import { Calendar } from "primereact/calendar";

// api related

// utils
import ActiveStatus from "../../utils/activeStatus";
import { useGetRole } from "../../hook/role.hook";
import { Dropdown } from "primereact/dropdown";
import UserApi from "../../api/UserApi";
import moment from "moment";
import Api from "../../api/Api";
import { Paginator } from "primereact/paginator";

const Users = ({ permissions }) => {
  // state
  const [globalFilter, setGlobalFilter] = useState(null);
  const [roleOptions, setRoleOptions] = useState([]);
  const [selectItems, setSelectItems] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showExportModal, setShowExportModal] = useState(false);
  const [showDeleteItemModal, setShowDeleteItemModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  // paggin
  const limit_page = 10;
  const [first, setFirst] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalData, setTotalData] = useState(5);
  const [searchValue] = useDebounce(globalFilter, 500);

  // api calling
  const {
    data: usersData,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["user", currentPage, searchValue],
    queryFn: async () => await getUsers(),
  });

  const { data: roleData, isLoading: isRoleDataLoading, isSuccess: isRoleDataSuccess } = useGetRole();

  // query
  const { isLoading: createLoading, mutate: crateMutate } = useMutation(async (data) => await UserApi.Add(data), {
    onSettled: (response, error) => {
      if (response.data.status === 200) {
        refetch();
        setShowCreateModal(false);
        toast.success("User Created!", { duration: 4000 });
      } else {
        refetch();
        setShowCreateModal(false);
        toast.error(response.data.message, { duration: 5000 });
      }
    },
  });
  const { isLoading: exportLoading, mutate: exportMutate } = useMutation(async (data) => await UserApi.Export(data), {
    onSettled: async (response, error) => {
      try {
        if (response.data.status !== 200) {
          throw new Error(response.data.message);
        }

        const a_element = document.createElement("a");
        a_element.href = response.data.data;
        a_element.click();
        a_element.download = "asdsadasd";

        setShowExportModal(false);
        exportForm.reset({});
      } catch (error) {
        toast.error(error.message);
      }
    },
  });
  const { isLoading: updateLoading, mutate: updateMutate } = useMutation(async (data) => await UserApi.Update(data), {
    onSettled: (response, error) => {
      if (response.data.status === 200) {
        refetch();
        setShowEditModal(false);
        toast.success("User Updated!", { duration: 4000 });
      } else {
        refetch();
        setShowEditModal(false);
        toast.error(response.data.message, { duration: 5000 });
      }
    },
  });
  const { isLoading: deleteLoading, mutate: deleteMutate } = useMutation(async (data) => await UserApi.Delete(data), {
    onSettled: (response, error) => {
      if (response.data.status === 200) {
        setSelectItems([]);
        refetch();
        setShowDeleteModal(false);
        setShowDeleteItemModal(false);
        toast.success("User Deleted!", { duration: 4000 });
      } else {
        setSelectItems([]);
        refetch();
        setShowDeleteModal(false);
        setShowDeleteItemModal(false);
        toast.error(response.data.message, { duration: 5000 });
      }
    },
  });

  // react hook
  const { register, handleSubmit, control, formState } = useForm();
  const updateForm = useForm();
  const exportForm = useForm();

  // functions
  const getUsers = async () => {
    try {
      let payload = {
        limit: limit_page,
        page: currentPage,
        sort_by: "created_at",
        sort_at: -1,
      };

      if (searchValue && searchValue.length) {
        payload.query = searchValue;
      }

      const response = await Api().get(`/user`, { params: payload });

      if (response.data.status !== 200) {
        throw new Error(response.data.message || "failed to get data");
      }

      setTotalData(response.data?.pages?.total_data);
      return response.data.data;
    } catch (error) {
      toast.error(error?.message || "failed to get data");
    }
  };

  const create = (data) => {
    crateMutate(data);
  };

  const update = (data) => {
    delete data.address;
    updateMutate({ ...data, user_id: data._id });
  };

  const onPageChange = (event) => {
    setCurrentPage(event.page + 1);
    setFirst(event.first);
  };

  const exportHandler = (data) => {
    let first_date = moment(data.range_time[0]).format("YYYY-MM-DD");
    let second_date = data.range_time[1] ? moment(data.range_time[1]).format("YYYY-MM-DD") : first_date;

    exportMutate({ start_date: first_date, end_date: second_date });
  };

  const showDeleteItemConfirmation = (data) => {
    setSelectItems([data]);
    setShowDeleteItemModal(true);
  };

  const deleteSelectedItem = () => {
    let payload = {
      user_id: [],
    };

    for (let i = 0; i < selectItems.length; i++) {
      payload.user_id.push(selectItems[i]._id);
    }

    deleteMutate(payload);
  };

  const confirmDeleteItem = () => {
    const data = selectItems[0];

    let payload = {
      user_id: [data._id],
    };
    deleteMutate(payload);
  };

  const confirmDeleteSelected = () => {
    setShowDeleteModal(true);
  };

  const editCategory = (data) => {
    updateForm.reset({ ...data });
    setShowEditModal(true);
  };

  // life cycle
  useEffect(() => {
    if (searchValue) {
      setFirst(0);
      setCurrentPage(1);
    }
  }, [searchValue]);

  useEffect(() => {
    if (!isRoleDataLoading && isRoleDataSuccess) {
      let temp_data = [];

      for (let i = 0; i < roleData.length; i++) {
        temp_data.push({ name: roleData[i].name, value: roleData[i]._id });
      }

      setRoleOptions(temp_data);
    }
  }, [isRoleDataLoading, roleData, isRoleDataSuccess]);

  // child components
  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <Button label="Export (CSV)" className="p-button-info mr-2" onClick={() => setShowExportModal((prev) => !prev)} />
          {permissions.delete && <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectItems.length} />}
        </div>
      </React.Fragment>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        {permissions.update && <Button icon="pi pi-pencil" className="p-button-rounded p-button-warning mr-2" onClick={() => editCategory(rowData)} />}
        {permissions.delete && <Button icon="pi pi-trash" className="p-button-rounded p-button-danger mt-2" onClick={() => showDeleteItemConfirmation(rowData)} />}
      </div>
    );
  };

  const statusBodyTemplate = (rowData) => {
    let status = rowData.active_status ? "instock" : "outofstock";
    return <span className={`product-badge status-${status}`}>{ActiveStatus(rowData.active_status)}</span>;
  };

  const header = () => {
    return (
      <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
        <h5 className="m-0">Manage User</h5>
        <span className="block mt-2 md:mt-0 p-input-icon-left">
          <i className="pi pi-search" />
          <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
        </span>
      </div>
    );
  };

  const deleteSingleItemFooter = () => {
    return (
      <>
        <Button label="No" icon="pi pi-times" className="p-button-text" onClick={() => setShowDeleteItemModal(false)} />
        <Button label="Yes" loading={deleteLoading} icon="pi pi-check" className="p-button-text" onClick={confirmDeleteItem} />
      </>
    );
  };

  const deleteMultipleItemFooter = () => {
    return (
      <>
        <Button label="No" icon="pi pi-times" className="p-button-text" onClick={() => setShowDeleteModal(false)} />
        <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedItem} loading={deleteLoading} />
      </>
    );
  };

  return (
    <>
      <div className="grid crud-demo">
        <div className="col-12">
          <div className="card">
            <Toaster />
            <Toolbar className="mb-4" left={leftToolbarTemplate}></Toolbar>
            <DataTable loading={isLoading} value={usersData} selection={selectItems} onSelectionChange={(e) => setSelectItems(e.value)} dataKey="_id" className="datatable-responsive" emptyMessage="No user found." header={header} responsiveLayout="scroll">
              <Column selectionMode="multiple" headerStyle={{ width: "3rem" }}></Column>
              <Column field="name" header="Name" sortable headerStyle={{ width: "auto", minWidth: "10rem" }}></Column>
              <Column field="email" header="E-Mail" sortable headerStyle={{ width: "20%", minWidth: "10rem" }}></Column>
              <Column body={statusBodyTemplate} header="Status" sortable headerStyle={{ width: "10%", minWidth: "10rem" }}></Column>
              <Column field="updated_at" header="Updated at" sortable headerStyle={{ width: "20%", minWidth: "10rem" }}></Column>
              <Column field="created_at" header="Created at" sortable headerStyle={{ width: "20%", minWidth: "10rem" }}></Column>
              {permissions.update || permissions.delete ? <Column header="Actions" body={actionBodyTemplate} headerStyle={{ width: "10%", minWidth: "10rem" }}></Column> : null}
            </DataTable>
            <Paginator first={first} rows={limit_page} totalRecords={totalData} onPageChange={onPageChange} />
          </div>
        </div>
      </div>

      {/* export */}
      <Dialog visible={showExportModal} style={{ width: "450px" }} header="Export User" modal className="p-fluid" footer onHide={() => setShowExportModal(false)}>
        <form onSubmit={exportForm.handleSubmit(exportHandler)}>
          <div className="field">
            <label className="block" htmlFor="range_time">
              Range Time
            </label>
            <Controller control={exportForm.control} rules={{ required: true }} name="range_time" render={({ field }) => <Calendar ref={field.ref} value={field.value} onChange={field.onChange} selectionMode="range" />} />
            {exportForm?.formState?.errors?.range_time && (
              <small id="name-help" className="p-error block">
                This field is required
              </small>
            )}
          </div>

          <div className="flex justify-content-end">
            <div className="flex">
              <Button type="button" onClick={() => setShowExportModal(false)} label="Cancel" icon="pi pi-times" className="p-button-text" />
              <Button loading={exportLoading} label="Export" icon="pi pi-check" className="p-button-text" />
            </div>
          </div>
        </form>
      </Dialog>

      {/* create */}
      <Dialog visible={showCreateModal} style={{ width: "450px" }} header="Create User" modal className="p-fluid" footer onHide={() => setShowCreateModal(false)}>
        <form onSubmit={handleSubmit(create)}>
          <div className="field">
            <label htmlFor="name">Name</label>
            <InputText placeholder="User name" {...register("name", { required: true })} id="name" type="text" className={formState.errors.name && "p-invalid"} />
            {formState.errors.name && (
              <small id="name-help" className="p-error block">
                This field is required
              </small>
            )}
          </div>
          <div className="field">
            <label htmlFor="email">Email</label>
            <InputText placeholder="Email address" {...register("email", { required: true })} id="name" type="text" className={formState.errors.email && "p-invalid"} />
            {formState.errors.name && (
              <small id="name-help" className="p-error block">
                This field is required
              </small>
            )}
          </div>
          <div className="field">
            <label htmlFor="phone_number">Phone Number</label>
            <InputText placeholder="Ex : 08522.." {...register("phone_number", { required: true })} id="name" type="text" className={formState.errors.phone_number && "p-invalid"} />
            {formState.errors.phone_number && (
              <small id="name-help" className="p-error block">
                This field is required
              </small>
            )}
          </div>

          <div className="field">
            <label className="block" htmlFor="active_status">
              Role
            </label>
            <Controller
              control={control}
              rules={{ required: true }}
              name="role_id"
              render={({ field }) => <Dropdown className={formState.errors.role_id && "p-invalid"} onBlur={field.onBlur} id="status" ref={field.ref} value={field.value} onChange={(e) => field.onChange(e)} options={roleOptions} optionLabel="name" placeholder="Select user role" />}
            />
            {formState.errors.role_id && (
              <small id="name-help" className="p-error block">
                This field is required
              </small>
            )}
          </div>
          <div className="field">
            <label className="block" htmlFor="active_status">
              Active status
            </label>
            <Controller defaultValue={false} control={control} name="active_status" render={({ field }) => <InputSwitch onChange={(e) => field.onChange(e)} checked={field.value} />} />
          </div>
          <div className="flex justify-content-end">
            <div className="flex">
              <Button type="button" onClick={() => setShowCreateModal(false)} label="Cancel" icon="pi pi-times" className="p-button-text" />
              <Button loading={createLoading} label="Save" icon="pi pi-check" className="p-button-text" />
            </div>
          </div>
        </form>
      </Dialog>

      {/* edit */}
      <Dialog visible={showEditModal} style={{ width: "450px" }} header="Edit Category" modal className="p-fluid" footer onHide={() => setShowEditModal(false)}>
        <form onSubmit={updateForm.handleSubmit(update)}>
          <div className="field">
            <label htmlFor="name">Name</label>
            <InputText placeholder="User name" {...updateForm.register("name", { required: true })} id="name" type="text" className={updateForm.formState.errors.name && "p-invalid"} />
            {updateForm.formState.errors.name && (
              <small id="name-help" className="p-error block">
                This field is required
              </small>
            )}
          </div>
          <div className="field">
            <label htmlFor="email">Email</label>
            <InputText placeholder="Email address" {...updateForm.register("email", { required: true })} id="name" type="text" className={updateForm.formState.errors.email && "p-invalid"} />
            {updateForm.formState.errors.name && (
              <small id="name-help" className="p-error block">
                This field is required
              </small>
            )}
          </div>
          <div className="field">
            <label htmlFor="phone_number">Phone Number</label>
            <InputText placeholder="Ex : 08522.." {...updateForm.register("phone_number", { required: true })} id="name" type="text" className={updateForm.formState.errors.phone_number && "p-invalid"} />
            {updateForm.formState.errors.phone_number && (
              <small id="name-help" className="p-error block">
                This field is required
              </small>
            )}
          </div>

          <div className="field">
            <label className="block" htmlFor="active_status">
              Role
            </label>
            <Controller
              control={updateForm.control}
              rules={{ required: true }}
              name="role_id"
              render={({ field }) => <Dropdown className={updateForm.formState.errors.role_id && "p-invalid"} onBlur={field.onBlur} id="status" ref={field.ref} value={field.value} onChange={(e) => field.onChange(e)} options={roleOptions} optionLabel="name" placeholder="Select user role" />}
            />
            {updateForm.formState.errors.role_id && (
              <small id="name-help" className="p-error block">
                This field is required
              </small>
            )}
          </div>
          <div className="field">
            <label className="block" htmlFor="active_status">
              Active status
            </label>
            <Controller defaultValue={false} control={updateForm.control} name="active_status" render={({ field }) => <InputSwitch onChange={(e) => field.onChange(e)} checked={field.value} />} />
          </div>
          <div className="flex justify-content-end">
            <div className="flex">
              <Button type="button" onClick={() => setShowEditModal(false)} label="Cancel" icon="pi pi-times" className="p-button-text" />
              <Button loading={updateLoading} label="Save" icon="pi pi-check" className="p-button-text" />
            </div>
          </div>
        </form>
      </Dialog>

      {/* delete single */}
      <Dialog visible={showDeleteItemModal} style={{ width: "450px" }} header="Confirm" modal footer={deleteSingleItemFooter} onHide={() => setShowDeleteItemModal(false)}>
        <div className="flex align-items-center justify-content-center">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
          {selectItems.length && (
            <span>
              Are you sure you want to delete <b>{selectItems[0]?.name}</b>?
            </span>
          )}
        </div>
      </Dialog>

      {/* delete multiple */}
      <Dialog visible={showDeleteModal} style={{ width: "450px" }} header="Confirm" modal footer={deleteMultipleItemFooter} onHide={() => setShowDeleteModal(false)}>
        <div className="flex align-items-center justify-content-center">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
          {selectItems.length && <span>Are you sure you want to delete the selected categories?</span>}
        </div>
      </Dialog>
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location?.pathname === nextProps.location?.pathname;
};

export default React.memo(Users, comparisonFn);
