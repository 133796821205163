// hooks
import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useMutation, useQuery } from "react-query";

// components
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import toast, { Toaster } from "react-hot-toast";

// utils
import TimeFormatter from "../utils/TimeFormatter";
import ReactQuill from "react-quill";
import reactQuill from "../config/reactQuill";
import "react-quill/dist/quill.snow.css";
import Api from "../api/Api";
import { useNavigate } from "react-router-dom";

const GlossaryItemPage = ({ permissions }) => {
  const navigate = useNavigate();

  // state
  const [globalFilter, setGlobalFilter] = useState(null);
  const [selectItems, setSelectItems] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDeleteItemModal, setShowDeleteItemModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [glossaryList, setGlossaryList] = useState([]);

  // api calling
  const { isLoading, isError, refetch } = useQuery("glossary-list", () => getList());

  // query
  const { isLoading: updateLoading, mutate: updateMutate } = useMutation(async (data) => await Api().post("glossary-alphabet/edit", data), {
    onSettled: (response, error) => {
      if (response.data.status === 200) {
        refetch();
        setShowEditModal(false);
        toast.success("Glossary Updated!", { duration: 4000 });
      } else {
        refetch();
        setShowEditModal(false);
        toast.error(response.data.message, { duration: 4000 });
      }
    },
  });
  const { isLoading: deleteLoading, mutate: deleteMutate } = useMutation(async (data) => await Api().delete("/glossary-alphabet", { data: data }), {
    onSettled: (response, error) => {
      if (response.data.status === 200) {
        setSelectItems([]);
        refetch();
        setShowDeleteModal(false);
        setShowDeleteItemModal(false);
        toast.success("Glossary Deleted!", { duration: 4000 });
      } else {
        setSelectItems([]);
        refetch();
        setShowDeleteModal(false);
        setShowDeleteItemModal(false);
        toast.error(response.data.message, { duration: 5000 });
      }
    },
  });

  // react hook
  const updateForm = useForm();

  // functions
  const getList = async () => {
    try {
      const response = await Api().get("/glossary-alphabet");

      if (response.data.status !== 200) {
        toast.error("Failed to get data");
        throw new Error("Error");
      }

      setGlossaryList(response.data.data);
      return response.data.data;
    } catch (error) {
      toast.error("An Error Occured !");
      console.log(error);
    }
  };

  const update = (data) => {
    let payload = {
      glossary_alphabet_id: data._id,
      title: data.title,
      description: data.description,
    };

    updateMutate(payload);
  };

  const showDeleteItemConfirmation = (data) => {
    setSelectItems([data]);
    setShowDeleteItemModal(true);
  };

  const deleteSelectedItem = () => {
    let payload = {
      glossary_alphabet_id: [],
    };

    for (let i = 0; i < selectItems.length; i++) {
      payload.glossary_alphabet_id.push(selectItems[i]._id);
    }

    deleteMutate(payload);
  };

  const confirmDeleteItem = () => {
    const data = selectItems[0];

    let payload = {
      glossary_alphabet_id: [data._id],
    };
    deleteMutate(payload);
  };

  const confirmDeleteSelected = () => {
    setShowDeleteModal(true);
  };

  const editItem = (data) => {
    updateForm.reset({ ...data });
    setShowEditModal(true);
  };

  // life cycle
  useEffect(() => {
    if (isError) {
      toast.error("Please check your connections", { duration: 6000 });
    }
  }, [isError]);

  // child components
  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          {permissions.create && <Button label="New" icon="pi pi-plus" className="p-button-success mr-2" onClick={() => navigate("/dashboard/glossary-list/add")} />}
          {permissions.delete && <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectItems.length} />}
        </div>
      </React.Fragment>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        {permissions.update && <Button icon="pi pi-pencil" className="p-button-rounded p-button-warning mr-2" onClick={() => editItem(rowData)} />}
        {permissions.delete && <Button icon="pi pi-trash" className="p-button-rounded p-button-danger mt-2" onClick={() => showDeleteItemConfirmation(rowData)} />}
      </div>
    );
  };

  const header = () => {
    return (
      <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
        <h5 className="m-0">Manage Glossary</h5>
        <span className="block mt-2 md:mt-0 p-input-icon-left">
          <i className="pi pi-search" />
          <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
        </span>
      </div>
    );
  };

  const deleteSingleItemFooter = () => {
    return (
      <>
        <Button label="No" icon="pi pi-times" className="p-button-text" onClick={() => setShowDeleteItemModal(false)} />
        <Button label="Yes" loading={deleteLoading} icon="pi pi-check" className="p-button-text" onClick={confirmDeleteItem} />
      </>
    );
  };

  const deleteMultipleItemFooter = () => {
    return (
      <>
        <Button label="No" icon="pi pi-times" className="p-button-text" onClick={() => setShowDeleteModal(false)} />
        <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedItem} loading={deleteLoading} />
      </>
    );
  };

  return (
    <>
      <div className="grid crud-demo">
        <div className="col-12">
          <div className="card">
            <Toaster />
            <Toolbar className="mb-4" left={leftToolbarTemplate}></Toolbar>
            <DataTable
              loading={isLoading}
              value={glossaryList}
              selection={selectItems}
              onSelectionChange={(e) => setSelectItems(e.value)}
              dataKey="_id"
              paginator
              rows={10}
              rowsPerPageOptions={[5, 10, 25]}
              className="datatable-responsive"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
              globalFilter={globalFilter}
              emptyMessage="No Glossary found."
              header={header}
              responsiveLayout="scroll"
            >
              <Column selectionMode="multiple" headerStyle={{ width: "3rem" }}></Column>
              <Column field="title" header="Title" sortable headerStyle={{ width: "auto", minWidth: "10rem" }}></Column>
              <Column body={(rowData) => TimeFormatter(rowData.created_at)} header="Created at" sortable headerStyle={{ width: "20%", minWidth: "10rem" }}></Column>
              {permissions.update || permissions.delete ? <Column header="Actions" body={actionBodyTemplate} headerStyle={{ width: "10%", minWidth: "10rem" }}></Column> : null}
            </DataTable>
          </div>
        </div>
      </div>

      {/* edit */}
      <Dialog visible={showEditModal} style={{ width: "650px" }} header="Edit Glossary" modal className="p-fluid" footer onHide={() => setShowEditModal(false)}>
        <form onSubmit={updateForm.handleSubmit(update)}>
          <div className="field">
            <label htmlFor="name">Glossary Name</label>
            <InputText placeholder="Input product name" {...updateForm.register("title", { required: true })} id="name" type="text" className={updateForm.formState.errors.name && "p-invalid"} />
            {updateForm.formState.errors.name && (
              <small id="name-help" className="p-error block">
                Name is required
              </small>
            )}
          </div>

          <div className="field ">
            <label htmlFor="user">Answer :</label>
            <Controller
              rules={{ required: true }}
              control={updateForm.control}
              defaultValue={""}
              name="description"
              render={({ field }) => <ReactQuill className="custom-quill" onBlur={field.onBlur} ref={field.ref} modules={reactQuill.modules} value={field.value} onChange={(e) => field.onChange(e)} theme="snow" />}
            />
          </div>
          <div className="flex justify-content-end">
            <div className="flex">
              <Button type="button" onClick={() => setShowEditModal(false)} label="Cancel" icon="pi pi-times" className="p-button-text" />
              <Button loading={updateLoading} label="Save" icon="pi pi-check" className="p-button-text" />
            </div>
          </div>
        </form>
      </Dialog>

      {/* delete single */}
      <Dialog visible={showDeleteItemModal} style={{ width: "450px" }} header="Confirm" modal footer={deleteSingleItemFooter} onHide={() => setShowDeleteItemModal(false)}>
        <div className="flex align-items-center justify-content-center">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
          {selectItems.length && (
            <span>
              Are you sure you want to delete <b>{selectItems[0]?.name}</b>?
            </span>
          )}
        </div>
      </Dialog>

      {/* delete multiple */}
      <Dialog visible={showDeleteModal} style={{ width: "450px" }} header="Confirm" modal footer={deleteMultipleItemFooter} onHide={() => setShowDeleteModal(false)}>
        <div className="flex align-items-center justify-content-center">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
          {selectItems.length && <span>Are you sure you want to delete the selected Glossary?</span>}
        </div>
      </Dialog>
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location?.pathname === nextProps.location?.pathname;
};

export default React.memo(GlossaryItemPage, comparisonFn);
