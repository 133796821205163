// hooks
import React, { useState, useEffect } from "react";
import { useMutation, useQuery } from "react-query";
import { Link } from "react-router-dom";
import { useDebounce } from "use-debounce";

// components
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import toast, { Toaster } from "react-hot-toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";

// api related
import ProductApi from "../../api/ProductApi";

// utils
import TimeFormatter from "../../utils/TimeFormatter";
import ActiveStatus from "../../utils/activeStatus";
import getImage from "../../utils/getImage";
import convertToIdr from "../../utils/convertToIdr";
import { Paginator } from "primereact/paginator";
import Api from "../../api/Api";

const Products = ({ permissions }) => {
  // state
  const [globalFilter, setGlobalFilter] = useState(null);
  const [products, setProducts] = useState([]);
  const [selectItems, setSelectItems] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDeleteItemModal, setShowDeleteItemModal] = useState(false);

  // paggin
  const limit_page = 10;
  const [first, setFirst] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalData, setTotalData] = useState(5);
  const [searchValue] = useDebounce(globalFilter, 500);

  // api calling
  const {
    data: productData,
    isLoading,
    isSuccess,
    isError,
    refetch,
  } = useQuery({
    queryKey: ["product", currentPage, searchValue],
    queryFn: async () => await getProduct(),
  });
  const { isLoading: deleteLoading, mutate: deleteMutate } = useMutation(async (data) => await ProductApi.Delete(data), {
    onSettled: (response, error) => {
      if (response.data.status === 200) {
        setSelectItems([]);
        refetch();
        setShowDeleteModal(false);
        setShowDeleteItemModal(false);
        toast.success("Product Deleted!", { duration: 4000 });
      } else {
        setSelectItems([]);
        refetch();
        setShowDeleteModal(false);
        setShowDeleteItemModal(false);
        toast.error(response.data.message, { duration: 5000 });
      }
    },
  });

  // functions
  const getProduct = async () => {
    try {
      let payload = {
        limit: limit_page,
        page: currentPage,
        sort_by: "created_at",
        sort_at: -1,
      };

      if (searchValue && searchValue.length) {
        payload.query = searchValue;
      }

      const response = await Api().get(`/product`, { params: payload });

      if (response.data.status !== 200) {
        throw new Error(response.data.message || "failed to get product");
      }

      setTotalData(response.data?.pages?.total_data);
      return response.data.data;
    } catch (error) {
      toast.error(error?.message || "failed to get product");
    }
  };

  const showDeleteItemConfirmation = (data) => {
    setSelectItems([data]);
    setShowDeleteItemModal(true);
  };

  const deleteSelectedItem = () => {
    let payload = {
      product_id: [],
    };

    for (let i = 0; i < selectItems.length; i++) {
      payload.product_id.push(selectItems[i]._id);
    }

    deleteMutate(payload);
  };

  const confirmDeleteItem = () => {
    const data = selectItems[0];

    let payload = {
      product_id: [data._id],
    };
    deleteMutate(payload);
  };

  const confirmDeleteSelected = () => {
    setShowDeleteModal(true);
  };

  const onPageChange = (event) => {
    setCurrentPage(event.page + 1);
    setFirst(event.first);
  };

  useEffect(() => {
    if (searchValue) {
      setFirst(0);
      setCurrentPage(1);
    }
  }, [searchValue]);

  useEffect(() => {
    if (!isLoading && isSuccess) {
      let product = productData;
      let temp_data = [];

      for (let i = 0; i < product.length; i++) {
        temp_data.push({ ...product[i], price: convertToIdr(product[i].price), updated_at: TimeFormatter(product[i].updated_at), created_at: TimeFormatter(product[i].created_at) });
      }

      setProducts(temp_data);
    }
  }, [isLoading, productData, isSuccess]);

  useEffect(() => {
    if (isError) {
      toast.error("Please check your connections", { duration: 6000 });
    }
  }, [isError]);

  // child components
  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          {permissions.create && (
            <Link to="/dashboard/products/create">
              <Button label="New" icon="pi pi-plus" className="p-button-success mr-2" />
            </Link>
          )}
          {permissions.delete && <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectItems.length} />}
        </div>
      </React.Fragment>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        {permissions.update && (
          <Link to={`/dashboard/products/update/${rowData._id}`}>
            <Button icon="pi pi-pencil" className="p-button-rounded p-button-warning mr-2" />
          </Link>
        )}
        {permissions.delete && <Button icon="pi pi-trash" className="p-button-rounded p-button-danger mt-2" onClick={() => showDeleteItemConfirmation(rowData)} />}
      </div>
    );
  };

  const header = () => {
    return (
      <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
        <h5 className="m-0">Manage Product</h5>
        <span className="block mt-2 md:mt-0 p-input-icon-left">
          <i className="pi pi-search" />
          <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
        </span>
      </div>
    );
  };

  const imageBodyTemplate = (rowData) => {
    if (!rowData?.images) {
      return;
    }

    let firstImage = rowData?.images[0];

    return (
      <>
        <img src={getImage(firstImage?.path, firstImage?.name)} alt={rowData.image} className="shadow-2" width="100" />
      </>
    );
  };

  const statusBodyTemplate = (rowData) => {
    let status = rowData.active_status ? "instock" : "outofstock";
    return <span className={`product-badge status-${status}`}>{ActiveStatus(rowData.active_status)}</span>;
  };

  const stockBodyTemplate = (rowData) => {
    let currentStock = parseInt(rowData.qty);
    return <span>{currentStock} Items</span>;
  };

  const deleteSingleItemFooter = () => {
    return (
      <>
        <Button label="No" icon="pi pi-times" className="p-button-text" onClick={() => setShowDeleteItemModal(false)} />
        <Button label="Yes" loading={deleteLoading} icon="pi pi-check" className="p-button-text" onClick={confirmDeleteItem} />
      </>
    );
  };

  const deleteMultipleItemFooter = () => {
    return (
      <>
        <Button label="No" icon="pi pi-times" className="p-button-text" onClick={() => setShowDeleteModal(false)} />
        <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedItem} loading={deleteLoading} />
      </>
    );
  };

  return (
    <>
      <div className="grid crud-demo">
        <div className="col-12">
          <div className="card">
            <Toaster />
            <Toolbar className="mb-4" left={leftToolbarTemplate}></Toolbar>
            <DataTable loading={isLoading} value={products} selection={selectItems} onSelectionChange={(e) => setSelectItems(e.value)} dataKey="_id" className="datatable-responsive" emptyMessage="No Product found." header={header} responsiveLayout="scroll">
              <Column selectionMode="multiple" headerStyle={{ width: "3rem" }}></Column>
              <Column field="a" header="Image" body={imageBodyTemplate} headerStyle={{ width: "10%", minWidth: "10rem" }}></Column>
              <Column field="name" header="Name" headerStyle={{ width: "auto", minWidth: "10rem" }}></Column>
              <Column body={statusBodyTemplate} header="Status" headerStyle={{ width: "10%", minWidth: "10rem" }}></Column>
              <Column body={stockBodyTemplate} header="Stock" headerStyle={{ width: "10%", minWidth: "10rem" }}></Column>
              <Column field="price" header="Price" headerStyle={{ width: "10%", minWidth: "10rem" }}></Column>
              <Column field="updated_at" header="Updated at" headerStyle={{ width: "10%", minWidth: "10rem" }}></Column>
              {permissions.update || permissions.delete ? <Column header="Actions" body={actionBodyTemplate} headerStyle={{ width: "10%", minWidth: "10rem" }}></Column> : null}
            </DataTable>
            <Paginator first={first} rows={limit_page} totalRecords={totalData} onPageChange={onPageChange} />
          </div>
        </div>
      </div>

      {/* delete single */}
      <Dialog visible={showDeleteItemModal} style={{ width: "450px" }} header="Confirm" modal footer={deleteSingleItemFooter} onHide={() => setShowDeleteItemModal(false)}>
        <div className="flex align-items-center justify-content-center">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
          {selectItems.length && (
            <span>
              Are you sure you want to delete <b>{selectItems[0]?.name}</b>?
            </span>
          )}
        </div>
      </Dialog>

      {/* delete multiple */}
      <Dialog visible={showDeleteModal} style={{ width: "450px" }} header="Confirm" modal footer={deleteMultipleItemFooter} onHide={() => setShowDeleteModal(false)}>
        <div className="flex align-items-center justify-content-center">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
          {selectItems.length && <span>Are you sure you want to delete the selected products?</span>}
        </div>
      </Dialog>
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location?.pathname === nextProps.location?.pathname;
};

export default React.memo(Products, comparisonFn);
