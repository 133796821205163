// hooks
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useDebounce } from "use-debounce";

// components
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import toast, { Toaster } from "react-hot-toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";

// utils
import TimeFormatter from "../utils/TimeFormatter";
import { InputNumber } from "primereact/inputnumber";
import { InputTextarea } from "primereact/inputtextarea";
import ProductApi from "../api/ProductApi";
import classNames from "classnames";
import { Paginator } from "primereact/paginator";
import Api from "../api/Api";

const ProductReviews = ({ permissions }) => {
  // state
  const [globalFilter, setGlobalFilter] = useState(null);
  const [selectItems, setSelectItems] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDeleteItemModal, setShowDeleteItemModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  // paggin
  const limit_page = 10;
  const [first, setFirst] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalData, setTotalData] = useState(5);
  const [searchValue] = useDebounce(globalFilter, 500);

  // api calling
  const {
    data: reviews,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["product-review", currentPage, searchValue],
    queryFn: async () => await getProductReview(),
  });
  // query
  const { isLoading: updateLoading, mutate: updateMutate } = useMutation(async (data) => await ProductApi.UpdateProductReview(data), {
    onSettled: (response, error) => {
      if (response.data.status === 200) {
        refetch();
        setShowEditModal(false);
        toast.success("Review Updated!", { duration: 4000 });
      } else {
        refetch();
        setShowEditModal(false);
        toast.error(response.data.message, { duration: 5000 });
      }
    },
  });

  const { isLoading: deleteLoading, mutate: deleteMutate } = useMutation(async (data) => await ProductApi.DeleteProductReviews(data), {
    onSettled: (response, error) => {
      if (response.data.status === 200) {
        setSelectItems([]);
        refetch();
        setShowDeleteModal(false);
        setShowDeleteItemModal(false);
        toast.success("Review Deleted!", { duration: 4000 });
      } else {
        setSelectItems([]);
        refetch();
        setShowDeleteModal(false);
        setShowDeleteItemModal(false);
        toast.error(response.data.message, { duration: 5000 });
      }
    },
  });

  // react hook
  const updateForm = useForm();

  // functions
  const getProductReview = async () => {
    try {
      let payload = {
        limit: limit_page,
        page: currentPage,
        sort_by: "created_at",
        sort_at: -1,
      };

      if (searchValue && searchValue.length) {
        payload.query = searchValue;
      }

      const response = await Api().get(`/review`, { params: payload });

      if (response.data.status !== 200) {
        throw new Error(response.data.message || "failed to get data");
      }

      setTotalData(response.data?.pages?.total_data);
      return response.data.data;
    } catch (error) {
      toast.error(error?.message || "failed to get data");
    }
  };

  const update = (data) => {
    delete data.username;
    updateMutate(data);
  };

  const onPageChange = (event) => {
    setCurrentPage(event.page + 1);
    setFirst(event.first);
  };

  const showDeleteItemConfirmation = (data) => {
    setSelectItems([data]);
    setShowDeleteItemModal(true);
  };

  const deleteSelectedItem = () => {
    let payload = {
      product_review_id: [],
    };

    for (let i = 0; i < selectItems.length; i++) {
      payload.product_review_id.push(selectItems[i]._id);
    }

    deleteMutate(payload);
  };

  const confirmDeleteItem = () => {
    const data = selectItems[0];

    let payload = {
      product_review_id: [data._id],
    };
    deleteMutate(payload);
  };

  const confirmDeleteSelected = () => {
    setShowDeleteModal(true);
  };

  const editHandler = (data) => {
    let payload = {
      product_review_id: data?._id,
      review: data?.review,
      product_id: data.product_id?._id,
      user_id: data.user_id?._id,
      username: data.user_id.name,
      rating: data.rating,
    };

    updateForm.reset(payload);
    setShowEditModal(true);
  };

  useEffect(() => {
    if (searchValue) {
      setFirst(0);
      setCurrentPage(1);
    }
  }, [searchValue]);

  // child components
  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2">{permissions.delete && <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectItems.length} />}</div>
      </React.Fragment>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        {permissions.update && <Button icon="pi pi-pencil" className="p-button-rounded p-button-warning mr-2" onClick={() => editHandler(rowData)} />}
        {permissions.delete && <Button icon="pi pi-trash" className="p-button-rounded p-button-danger mt-2" onClick={() => showDeleteItemConfirmation(rowData)} />}
      </div>
    );
  };

  const header = () => {
    return (
      <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
        <h5 className="m-0">Manage Product Reviews</h5>
        <span className="block mt-2 md:mt-0 p-input-icon-left">
          <i className="pi pi-search" />
          <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
        </span>
      </div>
    );
  };

  const deleteSingleItemFooter = () => {
    return (
      <>
        <Button label="No" icon="pi pi-times" className="p-button-text" onClick={() => setShowDeleteItemModal(false)} />
        <Button label="Yes" loading={deleteLoading} icon="pi pi-check" className="p-button-text" onClick={confirmDeleteItem} />
      </>
    );
  };

  const deleteMultipleItemFooter = () => {
    return (
      <>
        <Button label="No" icon="pi pi-times" className="p-button-text" onClick={() => setShowDeleteModal(false)} />
        <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedItem} loading={deleteLoading} />
      </>
    );
  };

  return (
    <>
      <div className="grid crud-demo">
        <div className="col-12">
          <div className="card">
            <Toaster />
            <Toolbar className="mb-4" left={leftToolbarTemplate}></Toolbar>
            <DataTable loading={isLoading} value={reviews} selection={selectItems} onSelectionChange={(e) => setSelectItems(e.value)} dataKey="_id" className="datatable-responsive" emptyMessage="No Review found." header={header} responsiveLayout="scroll">
              <Column selectionMode="multiple" headerStyle={{ width: "3rem" }}></Column>
              <Column field="user" header="User" body={(data) => <p>{data.user_id.name}</p>} headerStyle={{ width: "10%", minWidth: "10rem" }}></Column>
              <Column field="review" header="Review" headerStyle={{ width: "auto", minWidth: "10rem" }}></Column>
              <Column field="rating" header="Rating" headerStyle={{ width: "5%", minWidth: "5rem" }}></Column>
              <Column
                field="review"
                header="Product"
                body={(data) => (
                  <p
                    className={classNames({
                      "not-exist": !data?.product_id?.name,
                    })}
                  >
                    {data.product_id?.name ? data.product_id.name + " " + data.product_id?.category_id[0].name : "product has been deleted!"}
                  </p>
                )}
                headerStyle={{ width: "30%", minWidth: "10rem" }}
              ></Column>
              <Column field="created_at" body={(data) => <p>{TimeFormatter(data.created_at)}</p>} header="Created at" headerStyle={{ width: "10%", minWidth: "10rem" }}></Column>
              {permissions.update || permissions.delete ? <Column header="Actions" body={actionBodyTemplate} headerStyle={{ width: "10%", minWidth: "10rem" }}></Column> : null}
            </DataTable>
            <Paginator first={first} rows={limit_page} totalRecords={totalData} onPageChange={onPageChange} />
          </div>
        </div>
      </div>

      {/* edit */}
      <Dialog visible={showEditModal} style={{ width: "450px" }} header="Edit Product Reviews" modal className="p-fluid" footer onHide={() => setShowEditModal(false)}>
        <form onSubmit={updateForm.handleSubmit(update)}>
          <div className="field">
            <label htmlFor="name">User</label>
            <InputText placeholder="Input Name" disabled {...updateForm.register("username", { required: true })} id="name" type="text" className={updateForm.formState.errors.username && "p-invalid"} />
          </div>
          <div className="field">
            <label htmlFor="name">Rating</label>
            <Controller defaultValue="0" control={updateForm.control} name="rating" render={({ field }) => <InputNumber onBlur={field.onBlur} ref={field.ref} value={field.value} onValueChange={(e) => field.onChange(e)} showButtons mode="decimal" max={5} placeholder="0" />} />
          </div>
          <div className="field">
            <label htmlFor="user">Review :</label>
            <Controller
              rules={{ required: true }}
              control={updateForm.control}
              name="review"
              defaultValue={""}
              render={({ field }) => <InputTextarea value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} className={updateForm.formState.errors.review && "p-invalid"} placeholder="Write running text" autoResize rows="10" cols="30" />}
            />
          </div>
          <div className="flex justify-content-end">
            <div className="flex">
              <Button type="button" onClick={() => setShowEditModal(false)} label="Cancel" icon="pi pi-times" className="p-button-text" />
              <Button loading={updateLoading} label="Save" icon="pi pi-check" className="p-button-text" />
            </div>
          </div>
        </form>
      </Dialog>

      {/* delete single */}
      <Dialog visible={showDeleteItemModal} style={{ width: "450px" }} header="Confirm" modal footer={deleteSingleItemFooter} onHide={() => setShowDeleteItemModal(false)}>
        <div className="flex align-items-center justify-content-center">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
          {selectItems.length && (
            <span>
              Are you sure you want to delete <b>{selectItems[0]?.name}</b>?
            </span>
          )}
        </div>
      </Dialog>

      {/* delete multiple */}
      <Dialog visible={showDeleteModal} style={{ width: "450px" }} header="Confirm" modal footer={deleteMultipleItemFooter} onHide={() => setShowDeleteModal(false)}>
        <div className="flex align-items-center justify-content-center">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
          {selectItems.length && <span>Are you sure you want to delete the selected categories?</span>}
        </div>
      </Dialog>
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location?.pathname === nextProps.location?.pathname;
};

export default React.memo(ProductReviews, comparisonFn);
