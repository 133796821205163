// hooks
import React, { useEffect } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { useMutation } from "react-query";
// import { DevTool } from "@hookform/devtools";

// conponents
import "react-quill/dist/quill.snow.css";
import { InputText } from "primereact/inputtext";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { Link, useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import ReactQuill from "react-quill";
import reactQuill from "../config/reactQuill";
import "react-quill/dist/quill.snow.css";

// api related
import Api from "../api/Api";

const CreateProduct = () => {
  const navigate = useNavigate();

  // hooks
  const { control, register, handleSubmit } = useForm();
  const { fields, remove, append } = useFieldArray({
    control,
    name: "glossaries",
  });

  // query
  const { isLoading: createLoading, mutate: crateMutate } = useMutation(async (data) => await Api().post("/glossary-alphabet/multiple", data), {
    onSettled: (response) => {
      if (response.data.status === 200) {
        navigate("/dashboard/glossary-list");
      } else {
        toast.error(response.data.message, { duration: 5000 });
      }
    },
  });

  // functions
  const onSubmit = (data) => {
    crateMutate(data);
  };

  // components
  const leftToolbar = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <h4 className="uppercase" style={{ margin: 0 }}>
            Create Multiple Glossary
          </h4>
        </div>
      </React.Fragment>
    );
  };

  // lifecycle
  useEffect(() => {
    append({});
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {/* form stuff */}
      <Toaster />
      <form onSubmit={handleSubmit(onSubmit)} className="card">
        <Toolbar className="mb-4" left={leftToolbar} />
        {fields.map((item, index) => (
          <div key={item.id}>
            <div className="p-fluid formgrid grid">
              <div className="field col-12 md:col-6">
                <label htmlFor="user">Title :</label>
                <InputText placeholder="Input title" {...register(`glossaries.${index}.title`)} id="name" type="text" />
              </div>
              <div className="field col-12 md:col-1">
                <label className="block"></label> <br />
                <Button icon="pi pi-times block" onClick={() => remove(index)} className=" p-button-danger mr-4" />
              </div>
              <div className="field col-12 md:col-12">
                <label htmlFor="user">Description :</label>
                <Controller
                  rules={{ required: true }}
                  control={control}
                  defaultValue={""}
                  name={`glossaries.${index}.description`}
                  render={({ field }) => <ReactQuill className="custom-quill" onBlur={field.onBlur} ref={field.ref} modules={reactQuill.modules} value={field.value} onChange={(e) => field.onChange(e)} theme="snow" />}
                />
              </div>
            </div>
          </div>
        ))}
        <section>
          <Button type="button" label="Add Field" onClick={() => append({})} className=" p-button-primary mr-4" />
        </section>
        <div className="flex justify-content-center mt-4">
          <Button label="Save" loading={createLoading} className=" p-button-primary mr-4" />
          <Link to="/dashboard/pages">
            <Button type="button" label="Back" className=" p-button-secondary" />
          </Link>
        </div>
      </form>
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location?.path === nextProps.location?.path;
};

export default React.memo(CreateProduct, comparisonFn);
