import { useQuery } from "react-query";
import ClaimsApi from "../api/ClaimsApi";

export const useGetClaims = () => {
  const getProducts = async () => {
    const response = await ClaimsApi.Get();

    if (response.data.status !== 200) {
      throw new Error("An error has occured!");
    }

    return response.data.data;
  };

  return useQuery("claims", () => getProducts());
};
