import Api from "./Api";

export default {
  Get() {
    return Api().get("/home/review");
  },
  Update(data) {
    return Api().post(`/home/review/edit`, data);
  },
  Details(id) {
    return Api().get(`/home/review/${id}`);
  },
  Add(data) {
    return Api().post(`/home/review`, data);
  },
  Delete(data) {
    return Api().delete(`/home/review`, { data: data });
  },
};
