import Api from "./Api";

export default {
  Get() {
    return Api().get("/step");
  },
  Update(data) {
    return Api().post(`/step/edit`, data);
  },
  Details(id) {
    return Api().get(`/step/${id}`);
  },
  Add(data) {
    return Api().post(`/step`, data);
  },
  Delete(data) {
    return Api().delete(`/step`, { data: data });
  },
};
