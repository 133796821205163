// hooks
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";

// components
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import toast, { Toaster } from "react-hot-toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";

// api related
import FragranceApi from "../../api/FragranceApi";

// utils
import TimeFormatter from "../../utils/TimeFormatter";
import { useGetFragrance } from "../../hook/fragrance.hook";
import getImage from "../../utils/getImage";

const Fragrance = ({ permissions }) => {
  // state
  const [globalFilter, setGlobalFilter] = useState(null);
  const [categories, setCategories] = useState([]);
  const [selectItems, setSelectItems] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDeleteItemModal, setShowDeleteItemModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [uploadThumb, setUploadThum] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);

  // api calling
  const { data: fragranceData, isLoading, isSuccess, isError, refetch } = useGetFragrance();

  // query
  const { isLoading: createLoading, mutate: crateMutate } = useMutation(async (data) => await FragranceApi.Add(data), {
    onSettled: (response, error) => {
      if (response.data.status === 200) {
        refetch();
        setShowCreateModal(false);
        toast.success("Fragrance Created!", { duration: 4000 });
      } else {
        refetch();
        setShowCreateModal(false);
        toast.error(response.data.message, { duration: 5000 });
      }
    },
  });

  const { isLoading: updateLoading, mutate: updateMutate } = useMutation(async (data) => await FragranceApi.Update(data), {
    onSettled: (response, error) => {
      if (response.data.status === 200) {
        refetch();
        setShowEditModal(false);
        toast.success("Fragrance Updated!", { duration: 4000 });
      } else {
        refetch();
        setShowEditModal(false);
        toast.error(response.data.message, { duration: 5000 });
      }
    },
  });

  const { isLoading: deleteLoading, mutate: deleteMutate } = useMutation(async (data) => await FragranceApi.Delete(data), {
    onSettled: (response, error) => {
      if (response.data.status === 200) {
        setSelectItems([]);
        refetch();
        setShowDeleteModal(false);
        setShowDeleteItemModal(false);
        toast.success("Fragrance Deleted!", { duration: 4000 });
      } else {
        setSelectItems([]);
        refetch();
        setShowDeleteModal(false);
        setShowDeleteItemModal(false);
        toast.error(response.data.message, { duration: 5000 });
      }
    },
  });

  // react hook
  const { register, reset, handleSubmit, formState } = useForm();
  const updateForm = useForm();

  // functions
  const create = (data) => {
    // image required
    if (!selectedFile) {
      toast.error("Image Required!", { duration: 4000 });
      return;
    }

    const formData = new FormData();

    formData.append("files", selectedFile);
    formData.append("name", data.name);

    crateMutate(formData);
  };

  const update = (data) => {
    const formData = new FormData();

    if (selectedFile) {
      formData.append("files", selectedFile);
      delete data.images;
    } else {
      formData.append("images", JSON.stringify(data.images[0]));
    }

    formData.append("product_fragrance_id", data._id);
    formData.append("name", data.name);
    updateMutate(formData);
  };

  const showNewModal = () => {
    setUploadThum(null);
    reset({});
    setSelectedFile(null);
    setShowCreateModal(true);
  };

  const handleUpload = (e) => {
    const createBlob = URL.createObjectURL(e.target.files[0]);
    setSelectedFile(e.target.files[0]);
    setUploadThum(createBlob);
  };

  const showDeleteItemConfirmation = (data) => {
    setSelectItems([data]);
    setShowDeleteItemModal(true);
  };

  const deleteSelectedItem = () => {
    let payload = {
      product_fragrance_id: [],
    };

    for (let i = 0; i < selectItems.length; i++) {
      payload.product_fragrance_id.push(selectItems[i]._id);
    }

    deleteMutate(payload);
  };

  const confirmDeleteItem = () => {
    const data = selectItems[0];

    let payload = {
      product_fragrance_id: [data._id],
    };
    deleteMutate(payload);
  };

  const confirmDeleteSelected = () => {
    setShowDeleteModal(true);
  };

  const editFragrance = (data) => {
    const firstImage = data?.images[0];
    setUploadThum(getImage(firstImage.path, firstImage.name));
    updateForm.reset({ ...data });
    setShowEditModal(true);
  };

  // life cycle
  useEffect(() => {
    if (!isLoading && isSuccess) {
      let temp_data = [];

      for (let i = 0; i < fragranceData.length; i++) {
        temp_data.push({ ...fragranceData[i], updated_at: TimeFormatter(fragranceData[i].updated_at), created_at: TimeFormatter(fragranceData[i].created_at) });
      }

      setCategories(temp_data);
    }
  }, [isLoading, fragranceData, isSuccess]);

  useEffect(() => {
    if (isError) {
      toast.error("Please check your connections", { duration: 6000 });
    }
  }, [isError]);

  // child components
  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          {permissions.create && <Button label="New" icon="pi pi-plus" className="p-button-success mr-2" onClick={showNewModal} />}
          {permissions.delete && <Button disabled={!selectItems.length} label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} />}
        </div>
      </React.Fragment>
    );
  };

  const imageBodyTemplate = (rowData) => {
    let firstImage = rowData.images[0];

    return (
      <>
        <img src={getImage(firstImage.path, firstImage.name)} alt={rowData.image} className="shadow-2" width="100" />
      </>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        <Button disabled={!permissions.update} icon="pi pi-pencil" className="p-button-rounded p-button-warning mr-2" onClick={() => editFragrance(rowData)} />
        <Button disabled={!permissions.delete} icon="pi pi-trash" className="p-button-rounded p-button-danger mt-2" onClick={() => showDeleteItemConfirmation(rowData)} />
      </div>
    );
  };

  const header = () => {
    return (
      <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
        <h5 className="m-0">Manage Fragrances</h5>
        <span className="block mt-2 md:mt-0 p-input-icon-left">
          <i className="pi pi-search" />
          <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
        </span>
      </div>
    );
  };

  const deleteSingleItemFooter = () => {
    return (
      <>
        <Button label="No" icon="pi pi-times" className="p-button-text" onClick={() => setShowDeleteItemModal(false)} />
        <Button label="Yes" loading={deleteLoading} icon="pi pi-check" className="p-button-text" onClick={confirmDeleteItem} />
      </>
    );
  };

  const deleteMultipleItemFooter = () => {
    return (
      <>
        <Button label="No" icon="pi pi-times" className="p-button-text" onClick={() => setShowDeleteModal(false)} />
        <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedItem} loading={deleteLoading} />
      </>
    );
  };

  return (
    <>
      <div className="grid crud-demo">
        <div className="col-12">
          <div className="card">
            <Toaster />
            <Toolbar className="mb-4" left={leftToolbarTemplate}></Toolbar>
            <DataTable
              loading={isLoading}
              value={categories}
              selection={selectItems}
              onSelectionChange={(e) => setSelectItems(e.value)}
              dataKey="_id"
              paginator
              rows={10}
              rowsPerPageOptions={[5, 10, 25]}
              className="datatable-responsive"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
              globalFilter={globalFilter}
              emptyMessage="No Fragrance found."
              header={header}
              responsiveLayout="scroll"
            >
              <Column selectionMode="multiple" headerStyle={{ width: "3rem" }}></Column>
              <Column field="a" header="Image" body={imageBodyTemplate} headerStyle={{ width: "10%", minWidth: "10rem" }}></Column>
              <Column field="name" header="Name" sortable headerStyle={{ width: "auto", minWidth: "10rem" }}></Column>
              <Column field="updated_at" header="Updated at" sortable headerStyle={{ width: "20%", minWidth: "10rem" }}></Column>
              <Column field="created_at" header="Created at" sortable headerStyle={{ width: "20%", minWidth: "10rem" }}></Column>
              {permissions.update || permissions.delete ? <Column header="Actions" body={actionBodyTemplate} headerStyle={{ width: "10%", minWidth: "10rem" }}></Column> : null}
            </DataTable>
          </div>
        </div>
      </div>

      {/* create */}
      <Dialog visible={showCreateModal} style={{ width: "450px" }} header="Create Fragrance" modal className="p-fluid" footer onHide={() => setShowCreateModal(false)}>
        <form onSubmit={handleSubmit(create)}>
          <div className="field">
            <label htmlFor="name">Name</label>
            <InputText placeholder="Input fragrance name" {...register("name", { required: true })} id="name" type="text" className={formState.errors.name && "p-invalid"} />
            {formState.errors.name && (
              <small id="name-help" className="p-error block">
                This field is required
              </small>
            )}
          </div>
          <div className="field">
            <label htmlFor="description">Upload Image</label> <br />
            <input onChange={handleUpload} type="file" />
          </div>
          {uploadThumb && (
            <div className="">
              <img width="85px" src={uploadThumb} alt="thumbnails" />
            </div>
          )}
          {/* <div className="field">
            <label className="block" htmlFor="active_status">
              Active status
            </label>
            <Controller control={control} name="active_status" render={({ field }) => <InputSwitch onChange={(e) => field.onChange(e)} checked={field.value} />} />
          </div> */}
          <div className="flex justify-content-end">
            <div className="flex">
              <Button type="button" onClick={() => setShowCreateModal(false)} label="Cancel" icon="pi pi-times" className="p-button-text" />
              <Button loading={createLoading} label="Save" icon="pi pi-check" className="p-button-text" />
            </div>
          </div>
        </form>
      </Dialog>

      {/* edit */}
      <Dialog visible={showEditModal} style={{ width: "450px" }} header="Edit Fragrance" modal className="p-fluid" footer onHide={() => setShowEditModal(false)}>
        <form onSubmit={updateForm.handleSubmit(update)}>
          <div className="field">
            <label htmlFor="name">Name</label>
            <InputText placeholder="Input product name" {...updateForm.register("name", { required: true })} id="name" type="text" className={updateForm.formState.errors.name && "p-invalid"} />
            {updateForm.formState.errors.name && (
              <small id="name-help" className="p-error block">
                Name is required
              </small>
            )}
          </div>
          <div className="field">
            <label htmlFor="description">Upload Image</label> <br />
            <input onChange={handleUpload} type="file" />
          </div>
          <div className="">
            <img width="85px" src={uploadThumb} alt="thumbnails" />
          </div>
          <div className="flex justify-content-end">
            <div className="flex">
              <Button type="button" onClick={() => setShowEditModal(false)} label="Cancel" icon="pi pi-times" className="p-button-text" />
              <Button loading={updateLoading} label="Save" icon="pi pi-check" className="p-button-text" />
            </div>
          </div>
        </form>
      </Dialog>

      {/* delete single */}
      <Dialog visible={showDeleteItemModal} style={{ width: "450px" }} header="Confirm" modal footer={deleteSingleItemFooter} onHide={() => setShowDeleteItemModal(false)}>
        <div className="flex align-items-center justify-content-center">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
          {selectItems.length && (
            <span>
              Are you sure you want to delete <b>{selectItems[0]?.name}</b>?
            </span>
          )}
        </div>
      </Dialog>

      {/* delete multiple */}
      <Dialog visible={showDeleteModal} style={{ width: "450px" }} header="Confirm" modal footer={deleteMultipleItemFooter} onHide={() => setShowDeleteModal(false)}>
        <div className="flex align-items-center justify-content-center">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
          {selectItems.length && <span>Are you sure you want to delete the selected categories?</span>}
        </div>
      </Dialog>
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location?.pathname === nextProps.location?.pathname;
};

export default React.memo(Fragrance, comparisonFn);
