const ReactQuillConfig = {
    modules: {
        toolbar: [
            [{ font: [] }],
            [{ header: [1, 2, 3, 4, 5, 6] }],
            [{ size: ["small", "medium", "large", "huge"] }], // custom dropdown
            ["bold", "italic", "underline", "strike"], // toggled buttons
            ["blockquote"],
            ["image"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }], // superscript/subscript
            [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
            [{ direction: "rtl" }], // text direction

            [{ color: [] }, { background: [] }], // dropdown with defaults from theme
            [{ align: [] }],

            ["clean"], // remove formatting button
        ],
    },
    formats: ["header", "bold", "italic", "underline", "strike", "blockquote", "list", "bullet", "indent", "link", "image"],
};

export default ReactQuillConfig;
