// hooks
import React, { useState, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import { useQuery, useMutation } from "react-query";

// conponents
import "react-quill/dist/quill.snow.css";
import { InputText } from "primereact/inputtext";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { Link } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { InputTextarea } from "primereact/inputtextarea";

// config
import ReactQuill from "react-quill";
import reactQuill from "../config/reactQuill";
import "react-quill/dist/quill.snow.css";
import Api from "../api/Api";
import formDataDebugger from "../utils/formDataDebugger";

const AboutPage = ({ permissions }) => {
  // state
  const [id, setId] = useState(null);
  const [section1ImagePreview, setSection1ImagePreview] = useState(null);
  const [section1ImageMobilePreview, setSection1ImageMobilePreview] = useState(null);
  const [section2ImagePreview, setSection2ImagePreview] = useState(null);
  const [section3ImagePreview, setSection3ImagePreview] = useState(null);
  const [section3ImageBackgroundPreview, setSection3ImageBackgroundPreview] = useState(null);
  const [section4ImagePreview, setSection4ImagePreview] = useState(null);
  const [section4ImageBackgroundPreview, setSection4ImageBackgroundPreview] = useState(null);

  const section1ImageRef = useRef(null);
  const section1ImageMobileRef = useRef(null);
  const section2ImageRef = useRef(null);
  const section3ImageRef = useRef(null);
  const section3ImageBackgroundRef = useRef(null);
  const section4ImageRef = useRef(null);
  const section4ImageBackgroundRef = useRef(null);

  // hooks
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const { isLoading } = useQuery("About-details", () => getDetails(), { refetchOnWindowFocus: false });
  const { isLoading: updateLoading, mutate } = useMutation((data) => Api().post("/about/page/edit", data), {
    onSettled: (response, error) => {
      if (error) {
        toast.error("An Error Occured while update");
        return;
      }

      if (response.data.status !== 200) {
        toast.error(response.data.message);
        return;
      }

      toast.success("Update Success");
    },
  });

  // functions
  const onSubmit = (data) => {
    const formData = new FormData();

    // images ref
    const section1Image = section1ImageRef.current.files[0];
    const section1ImageMobil = section1ImageMobileRef.current.files[0];
    const section2Image = section2ImageRef.current.files[0];
    const section3Image = section3ImageRef.current.files[0];
    const section3ImageBackground = section3ImageBackgroundRef.current.files[0];
    const section4Image = section4ImageRef.current.files[0];
    const section4ImageBackground = section4ImageBackgroundRef.current.files[0];

    if (!section1Image && !data.section1_images[0]) return toast.error("section 1 image required");
    if (!section1ImageMobil && !data.section1_images_mobile[0]) return toast.error("section 1 image required");
    if (!section2Image && !data.section2_images[0]) return toast.error("section 1 image required");
    if (!section3Image && !data.section3_images[0]) return toast.error("section 1 image required");
    if (!section3ImageBackground && !data.section3_background_images[0]) return toast.error("section 1 image required");
    if (!section4Image && !data.section4_images[0]) return toast.error("section 1 image required");
    if (!section4ImageBackground && !data.section4_background_images[0]) return toast.error("section 1 image required");

    if (section1Image) {
      formData.append("section1_images", section1Image);
    } else {
      formData.append("section1_images", JSON.stringify(data.section1_images[0]));
    }

    if (section1ImageMobil) {
      formData.append("section1_images_mobile", section1ImageMobil);
    } else {
      formData.append("section1_images_mobile", JSON.stringify(data.section1_images_mobile[0]));
    }

    if (section2Image) {
      formData.append("section2_images", section2Image);
    } else {
      formData.append("section2_images", JSON.stringify(data.section2_images[0]));
    }

    if (section3Image) {
      formData.append("section3_images", section3Image);
    } else {
      formData.append("section3_images", JSON.stringify(data.section3_images[0]));
    }

    if (section3ImageBackground) {
      formData.append("section3_background_images", section3ImageBackground);
    } else {
      formData.append("section3_background_images", JSON.stringify(data.section3_background_images[0]));
    }

    if (section4Image) {
      formData.append("section4_images", section4Image);
    } else {
      formData.append("section4_images", JSON.stringify(data.section4_images[0]));
    }

    if (section4ImageBackground) {
      formData.append("section4_background_images", section4ImageBackground);
    } else {
      formData.append("section4_background_images", JSON.stringify(data.section4_background_images[0]));
    }

    // delete unused obj
    delete data?.section1_images;
    delete data?.section1_images_mobile;
    delete data?.section2_images;
    delete data?.section3_images;
    delete data?.section3_background_images;
    delete data?.section4_images;
    delete data?.section4_background_images;
    delete data?.created_at;
    delete data?._id;
    delete data?.__v;

    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });

    formData.append("about_id", id);

    formDataDebugger(formData);
    mutate(formData);
  };

  const getDetails = async () => {
    try {
      const response = await Api().get("/about/detail");

      if (response.data.status !== 200) {
        toast.error(response.data.message);
        return;
      }

      setId(response?.data?.data?._id);
      const details = response.data.data;
      setSection1ImagePreview(details?.section1_images[0]?.url ? details.section1_images[0].url : null);
      setSection1ImageMobilePreview(details?.section1_images_mobile[0]?.url ? details.section1_images_mobile[0].url : null);
      setSection2ImagePreview(details?.section2_images[0]?.url ? details.section2_images[0].url : null);
      setSection3ImagePreview(details?.section3_images[0]?.url ? details.section3_images[0].url : null);
      setSection3ImageBackgroundPreview(details?.section3_background_images[0]?.url ? details.section3_background_images[0].url : null);
      setSection4ImagePreview(details?.section4_images[0]?.url ? details.section4_images[0].url : null);
      setSection4ImageBackgroundPreview(details?.section4_background_images[0]?.url ? details.section4_background_images[0].url : null);
      reset(details);

      return details;
    } catch (error) {
      console.log(error);
      toast.error("An Error Occured!");
    }
  };

  // components
  const leftToolbar = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <h4 className="uppercase" style={{ margin: 0 }}>
            About Setting
          </h4>
        </div>
      </React.Fragment>
    );
  };

  const ImagePreview = ({ image }) => {
    return (
      image && (
        <div className="field col-12 md:col-3">
          <img style={{ maxWidth: "250px" }} src={image} alt={"section 1 preview"} />
        </div>
      )
    );
  };

  return (
    <>
      <Toaster />
      <form onSubmit={handleSubmit(onSubmit)} className="card">
        <Toolbar className="mb-4" left={leftToolbar} />
        <div>
          <div className="field col-12 md:col-12">
            {/* section 1 */}
            <div className="p-fluid formgrid grid ">
              <div className="field col-12 md:col-6">
                <label htmlFor="section1_title">Title Section 1 :</label>
                <Controller
                  rules={{ required: true }}
                  control={control}
                  defaultValue={""}
                  name="section1_title"
                  render={({ field }) => <InputText placeholder="Input Title" value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="section1_title" type="text" className={errors.section1_title && "p-invalid"} />}
                />
              </div>
              <div className="field col-12 md:col-12">
                <div className="field p-fluid">
                  <label>Description Section 1 :</label>
                  <Controller
                    rules={{ required: true }}
                    control={control}
                    name="section1_description"
                    render={({ field }) => <ReactQuill className="custom-quill" onBlur={field.onBlur} ref={field.ref} modules={reactQuill.modules} value={field.value} onChange={(e) => field.onChange(e)} theme="snow" />}
                  />
                </div>
              </div>
              <div className="field col-12 md:col-12 p-fluid formgrid grid">
                <div className="field col-12 md:col-6">
                  <div className="field col-12 md:col-12">
                    <div className="field p-fluid">
                      <label>Top Image :</label> <br />
                      <input
                        onChange={(e) => {
                          setSection1ImagePreview(URL.createObjectURL(e.target.files[0]));
                        }}
                        ref={section1ImageRef}
                        className="mt-2"
                        accept="image/*"
                        type="file"
                      />
                    </div>
                  </div>
                  <ImagePreview image={section1ImagePreview} />
                </div>
                <div className="field col-12 md:col-6">
                  <div className="field col-12 md:col-12">
                    <div className="field p-fluid">
                      <label>Top Image Mobile Size :</label> <br />
                      <input
                        onChange={(e) => {
                          setSection1ImageMobilePreview(URL.createObjectURL(e.target.files[0]));
                        }}
                        ref={section1ImageMobileRef}
                        className="mt-2"
                        accept="image/*"
                        type="file"
                      />
                    </div>
                  </div>
                  <ImagePreview image={section1ImageMobilePreview} />
                </div>
              </div>
            </div>

            {/* section 2 */}
            <div className="p-fluid formgrid grid ">
              <div className="field col-12 md:col-6">
                <label htmlFor="section2_title">Title Section 2 :</label>
                <Controller
                  rules={{ required: true }}
                  control={control}
                  defaultValue={""}
                  name="section2_title"
                  render={({ field }) => <InputText placeholder="Input Title" value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="section2_title" type="text" className={errors.section2_title && "p-invalid"} />}
                />
              </div>
              <div className="field col-12 md:col-12">
                <div className="field p-fluid">
                  <label>Description Section 2 :</label>
                  <Controller
                    rules={{ required: true }}
                    control={control}
                    name="section2_description"
                    render={({ field }) => <ReactQuill className="custom-quill" onBlur={field.onBlur} ref={field.ref} modules={reactQuill.modules} value={field.value} onChange={(e) => field.onChange(e)} theme="snow" />}
                  />
                </div>
              </div>
              <div className="field col-12 md:col-12 p-fluid formgrid grid">
                <div className="field col-12 md:col-6">
                  <div className="field col-12 md:col-12">
                    <div className="field p-fluid">
                      <label>Section 2 Image :</label> <br />
                      <input
                        onChange={(e) => {
                          setSection2ImagePreview(URL.createObjectURL(e.target.files[0]));
                        }}
                        ref={section2ImageRef}
                        className="mt-2"
                        accept="image/*"
                        type="file"
                      />
                    </div>
                  </div>
                  <ImagePreview image={section2ImagePreview} />
                </div>
              </div>
            </div>

            {/* section 3 */}
            <div className="p-fluid formgrid grid ">
              <div className="field col-12 md:col-6">
                <label htmlFor="section3_title">Title Section 3 :</label>
                <Controller
                  rules={{ required: true }}
                  control={control}
                  defaultValue={""}
                  name="section3_title"
                  render={({ field }) => <InputText placeholder="Input Title" value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="section3_title" type="text" className={errors.section3_title && "p-invalid"} />}
                />
              </div>
              <div className="field col-12 md:col-12">
                <div className="field p-fluid">
                  <label>Description Section 3 :</label>
                  <Controller
                    rules={{ required: true }}
                    control={control}
                    name="section3_description"
                    render={({ field }) => <ReactQuill className="custom-quill" onBlur={field.onBlur} ref={field.ref} modules={reactQuill.modules} value={field.value} onChange={(e) => field.onChange(e)} theme="snow" />}
                  />
                </div>
              </div>
              <div className="field col-12 md:col-12 p-fluid formgrid grid">
                <div className="field col-12 md:col-6">
                  <div className="field col-12 md:col-12">
                    <div className="field p-fluid">
                      <label>Section 3 Image :</label> <br />
                      <input
                        onChange={(e) => {
                          setSection3ImagePreview(URL.createObjectURL(e.target.files[0]));
                        }}
                        ref={section3ImageRef}
                        className="mt-2"
                        accept="image/*"
                        type="file"
                      />
                    </div>
                  </div>
                  <ImagePreview image={section3ImagePreview} />
                </div>
                <div className="field col-12 md:col-6">
                  <div className="field col-12 md:col-12">
                    <div className="field p-fluid">
                      <label>Section 3 Background Image :</label> <br />
                      <input
                        onChange={(e) => {
                          setSection3ImageBackgroundPreview(URL.createObjectURL(e.target.files[0]));
                        }}
                        ref={section3ImageBackgroundRef}
                        className="mt-2"
                        accept="image/*"
                        type="file"
                      />
                    </div>
                  </div>
                  <ImagePreview image={section3ImageBackgroundPreview} />
                </div>
              </div>
            </div>

            {/* section 4 */}
            <div className="p-fluid formgrid grid ">
              <div className="field col-12 md:col-6">
                <label htmlFor="section4_title">Title Section 4 :</label>
                <Controller
                  rules={{ required: true }}
                  control={control}
                  defaultValue={""}
                  name="section4_title"
                  render={({ field }) => <InputText placeholder="Input title" value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="section4_title" type="text" className={errors.name && "p-invalid"} />}
                />
              </div>
              <div className="field col-12 md:col-3">
                <label htmlFor="section4_button_name">Button Name Section 4 :</label>
                <Controller
                  rules={{ required: true }}
                  control={control}
                  defaultValue={""}
                  name="section4_button_name"
                  render={({ field }) => <InputText placeholder="Input Button Name" value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="section4_button_name" type="text" className={errors.section4_button_name && "p-invalid"} />}
                />
              </div>
              <div className="field col-12 md:col-3">
                <label htmlFor="section4_button_route">Button Route Section 4 :</label>
                <Controller
                  rules={{ required: true }}
                  control={control}
                  defaultValue={""}
                  name="section4_button_route"
                  render={({ field }) => <InputText placeholder="Input Button Route Section" value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="section4_button_route" type="text" className={errors.section4_button_route && "p-invalid"} />}
                />
              </div>
              <div className="field col-12 md:col-12">
                <div className="field p-fluid">
                  <label>Description Section 4 :</label>
                  <Controller
                    rules={{ required: true }}
                    control={control}
                    name="section4_description"
                    render={({ field }) => <ReactQuill className="custom-quill" onBlur={field.onBlur} ref={field.ref} modules={reactQuill.modules} value={field.value} onChange={(e) => field.onChange(e)} theme="snow" />}
                  />
                </div>
              </div>
              <div className="field col-12 md:col-12">
                <div className="field p-fluid">
                  <label>Running Text :</label>
                  <Controller
                    rules={{ required: true }}
                    control={control}
                    name="section4_running_text"
                    defaultValue={""}
                    render={({ field }) => <InputTextarea value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} className={errors.section4_running_tex && "p-invalid"} placeholder="Write running text" autoResize rows="10" cols="30" />}
                  />
                </div>
              </div>
              <div className="field col-12 md:col-12 p-fluid formgrid grid">
                <div className="field col-12 md:col-6">
                  <div className="field col-12 md:col-12">
                    <div className="field p-fluid">
                      <label>Section 4 Image :</label> <br />
                      <input
                        onChange={(e) => {
                          setSection4ImagePreview(URL.createObjectURL(e.target.files[0]));
                        }}
                        ref={section4ImageRef}
                        className="mt-2"
                        accept="image/*"
                        type="file"
                      />
                    </div>
                  </div>
                  <ImagePreview image={section4ImagePreview} />
                </div>
                <div className="field col-12 md:col-6">
                  <div className="field col-12 md:col-12">
                    <div className="field p-fluid">
                      <label>Section 4 Background Image :</label> <br />
                      <input
                        onChange={(e) => {
                          setSection4ImageBackgroundPreview(URL.createObjectURL(e.target.files[0]));
                        }}
                        ref={section4ImageBackgroundRef}
                        className="mt-2"
                        accept="image/*"
                        type="file"
                      />
                    </div>
                  </div>
                  <ImagePreview image={section4ImageBackgroundPreview} />
                </div>
              </div>
            </div>
          </div>

          {/* buttons */}
          <div className="flex justify-content-center mt-4">
            {permissions.update && <Button loading={updateLoading} disabled={isLoading} label="Save" className=" p-button-primary mr-4" />}
            <Link to="/dashboard">
              <Button type="button" label="Back" className=" p-button-secondary" />
            </Link>
          </div>
        </div>
      </form>
      {/* <DevTool control={control} /> */}
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location?.path === nextProps.location?.path;
};

export default React.memo(AboutPage, comparisonFn);
