import Api from "./Api";

export default {
  Get() {
    return Api().get("/shop");
  },
  Update(data) {
    return Api().post(`/shop/edit`, data);
  },
  Add(data) {
    return Api().post(`/shop`, data);
  },
  Delete(data) {
    return Api().delete(`/shop`, { data: data });
  },
};
