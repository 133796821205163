// hooks
import React, { useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation } from "react-query";

// components
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import toast, { Toaster } from "react-hot-toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";

// api related
import QuizApi from "../../api/QuizApi";

// utils
import TimeFormatter from "../../utils/TimeFormatter";
import { useGetQuiz } from "../../hook/quiz.hook";
import { InputTextarea } from "primereact/inputtextarea";
import { InputNumber } from "primereact/inputnumber";

const Quiz = ({ permissions }) => {
  // state
  const [globalFilter, setGlobalFilter] = useState(null);
  const [categories, setCategories] = useState([]);
  const [selectItems, setSelectItems] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDeleteItemModal, setShowDeleteItemModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  // api calling
  const { data: quizAnswers, isLoading, isSuccess, isError, refetch } = useGetQuiz();

  // query
  const { isLoading: createLoading, mutate: crateMutate } = useMutation(async (data) => await QuizApi.Add(data), {
    onSettled: (response, error) => {
      if (response.data.status === 200) {
        refetch();
        setShowCreateModal(false);
        toast.success("Question Created!", { duration: 4000 });
      } else {
        refetch();
        setShowCreateModal(false);
        toast.error(response.data.message, { duration: 5000 });
      }
    },
  });

  const { isLoading: updateLoading, mutate: updateMutate } = useMutation(async (data) => await QuizApi.Update(data), {
    onSettled: (response, error) => {
      if (response.data.status === 200) {
        refetch();
        setShowEditModal(false);
        toast.success("Question Updated!", { duration: 4000 });
      } else {
        refetch();
        setShowEditModal(false);
        toast.error(response.data.message, { duration: 5000 });
      }
    },
  });

  const { isLoading: deleteLoading, mutate: deleteMutate } = useMutation(async (data) => await QuizApi.Delete(data), {
    onSettled: (response, error) => {
      if (response.data.status === 200) {
        setSelectItems([]);
        refetch();
        setShowDeleteModal(false);
        setShowDeleteItemModal(false);
        toast.success("Question Deleted!", { duration: 4000 });
      } else {
        setSelectItems([]);
        refetch();
        setShowDeleteModal(false);
        setShowDeleteItemModal(false);
        toast.error(response.data.message, { duration: 5000 });
      }
    },
  });

  // react hook
  const { register, reset, handleSubmit, formState, control } = useForm();
  const updateForm = useForm();

  // functions
  const create = (data) => {
    crateMutate(data);
  };

  const update = (data) => {
    updateMutate(data);
  };

  const showNewModal = () => {
    reset({});
    setShowCreateModal(true);
  };

  const showDeleteItemConfirmation = (data) => {
    setSelectItems([data]);
    setShowDeleteItemModal(true);
  };

  const deleteSelectedItem = () => {
    let payload = {
      quiz_id: [],
    };

    for (let i = 0; i < selectItems.length; i++) {
      payload.quiz_id.push(selectItems[i]._id);
    }

    deleteMutate(payload);
  };

  const confirmDeleteItem = () => {
    const data = selectItems[0];

    let payload = {
      quiz_id: [data._id],
    };
    deleteMutate(payload);
  };

  const confirmDeleteSelected = () => {
    setShowDeleteModal(true);
  };

  const editAnswer = (data) => {
    // const {} = data
    updateForm.reset({ ...data, quiz_id: data._id });
    setShowEditModal(true);
  };

  // life cycle
  useEffect(() => {
    if (!isLoading && isSuccess) {
      let temp_data = [];

      for (let i = 0; i < quizAnswers.length; i++) {
        temp_data.push({ ...quizAnswers[i], updated_at: TimeFormatter(quizAnswers[i].updated_at), created_at: TimeFormatter(quizAnswers[i].created_at) });
      }
      setCategories(temp_data);
    }
  }, [isLoading, quizAnswers, isSuccess]);

  useEffect(() => {
    if (isError) {
      toast.error("Please check your connections", { duration: 6000 });
    }
  }, [isError]);

  // child components
  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          {permissions.create && <Button label="New" icon="pi pi-plus" className="p-button-success mr-2" onClick={showNewModal} />}
          {permissions.delete && <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectItems.length} />}
        </div>
      </React.Fragment>
    );
  };

  const actionBodyTemplate = (rowData) => {
    // console.log(rowData);
    return (
      <div className="actions">
        {permissions.update && <Button icon="pi pi-pencil" className="p-button-rounded p-button-warning mr-2" onClick={() => editAnswer(rowData)} />}
        {permissions.delete && <Button icon="pi pi-trash" className="p-button-rounded p-button-danger mt-2" onClick={() => showDeleteItemConfirmation(rowData)} />}
      </div>
    );
  };

  const header = () => {
    return (
      <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
        <h5 className="m-0">Manage Questions</h5>
        <span className="block mt-2 md:mt-0 p-input-icon-left">
          <i className="pi pi-search" />
          <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
        </span>
      </div>
    );
  };

  const deleteSingleItemFooter = () => {
    return (
      <>
        <Button label="No" icon="pi pi-times" className="p-button-text" onClick={() => setShowDeleteItemModal(false)} />
        <Button label="Yes" loading={deleteLoading} icon="pi pi-check" className="p-button-text" onClick={confirmDeleteItem} />
      </>
    );
  };

  const deleteMultipleItemFooter = () => {
    return (
      <>
        <Button label="No" icon="pi pi-times" className="p-button-text" onClick={() => setShowDeleteModal(false)} />
        <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedItem} loading={deleteLoading} />
      </>
    );
  };

  return (
    <>
      <div className="grid crud-demo">
        <div className="col-12">
          <div className="card">
            <Toaster />
            <Toolbar className="mb-4" left={leftToolbarTemplate}></Toolbar>
            <DataTable
              loading={isLoading}
              value={categories}
              selection={selectItems}
              onSelectionChange={(e) => setSelectItems(e.value)}
              dataKey="_id"
              paginator
              rows={10}
              rowsPerPageOptions={[5, 10, 25]}
              className="datatable-responsive"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
              globalFilter={globalFilter}
              emptyMessage="No Question found."
              header={header}
              responsiveLayout="scroll"
            >
              <Column selectionMode="multiple" headerStyle={{ width: "3rem" }}></Column>
              <Column field="question" header="Questions" sortable headerStyle={{ width: "auto", minWidth: "10rem" }}></Column>
              <Column field="step" header="Step" sortable headerStyle={{ width: "10%", minWidth: "10rem" }}></Column>
              <Column field="updated_at" header="Updated at" sortable headerStyle={{ width: "20%", minWidth: "10rem" }}></Column>
              <Column field="created_at" header="Created at" sortable headerStyle={{ width: "20%", minWidth: "10rem" }}></Column>
              {permissions.update || permissions.delete ? <Column header="Actions" body={actionBodyTemplate} headerStyle={{ width: "10%", minWidth: "10rem" }}></Column> : null}
            </DataTable>
          </div>
        </div>
      </div>

      {/* create */}
      <Dialog visible={showCreateModal} style={{ width: "450px" }} header="Create Question" modal className="p-fluid" footer onHide={() => setShowCreateModal(false)}>
        <form onSubmit={handleSubmit(create)}>
          <div className="field">
            <label htmlFor="name">Questions</label>
            <InputTextarea {...register("question", { required: true })} className={formState.errors.question && "p-invalid"} placeholder="Write product description" autoResize rows="10" cols="30" />
          </div>
          <div className="field">
            <label htmlFor="name">Step</label>
            <Controller defaultValue="0" control={control} name="step" render={({ field }) => <InputNumber onBlur={field.onBlur} ref={field.ref} value={field.value} onValueChange={(e) => field.onChange(e)} showButtons mode="decimal" placeholder="0" />} />
          </div>

          <div className="flex justify-content-end">
            <div className="flex">
              <Button type="button" onClick={() => setShowCreateModal(false)} label="Cancel" icon="pi pi-times" className="p-button-text" />
              <Button loading={createLoading} label="Save" icon="pi pi-check" className="p-button-text" />
            </div>
          </div>
        </form>
      </Dialog>

      {/* edit */}
      <Dialog visible={showEditModal} style={{ width: "450px" }} header="Edit Question" modal className="p-fluid" footer onHide={() => setShowEditModal(false)}>
        <form onSubmit={updateForm.handleSubmit(update)}>
          <div className="field">
            <label htmlFor="name">Questions</label>
            <InputTextarea {...updateForm.register("question", { required: true })} className={updateForm.formState.errors.question && "p-invalid"} placeholder="Write product description" autoResize rows="10" cols="30" />
          </div>
          <div className="field">
            <label htmlFor="name">Step</label>
            <Controller defaultValue="0" control={updateForm.control} name="step" render={({ field }) => <InputNumber onBlur={field.onBlur} ref={field.ref} value={field.value} onValueChange={(e) => field.onChange(e)} showButtons mode="decimal" placeholder="0" />} />
          </div>

          <div className="flex justify-content-end">
            <div className="flex">
              <Button type="button" onClick={() => setShowEditModal(false)} label="Cancel" icon="pi pi-times" className="p-button-text" />
              <Button loading={updateLoading} label="Save" icon="pi pi-check" className="p-button-text" />
            </div>
          </div>
        </form>
      </Dialog>

      {/* delete single */}
      <Dialog visible={showDeleteItemModal} style={{ width: "450px" }} header="Confirm" modal footer={deleteSingleItemFooter} onHide={() => setShowDeleteItemModal(false)}>
        <div className="flex align-items-center justify-content-center">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
          {selectItems.length && (
            <span>
              Are you sure you want to delete <b>{selectItems[0]?.name}</b>?
            </span>
          )}
        </div>
      </Dialog>

      {/* delete multiple */}
      <Dialog visible={showDeleteModal} style={{ width: "450px" }} header="Confirm" modal footer={deleteMultipleItemFooter} onHide={() => setShowDeleteModal(false)}>
        <div className="flex align-items-center justify-content-center">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
          {selectItems.length && <span>Are you sure you want to delete the selected categories?</span>}
        </div>
      </Dialog>
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location?.pathname === nextProps.location?.pathname;
};

export default React.memo(Quiz, comparisonFn);
