import axios from "axios";
import BaseUrl from "./BaseURL";
import Cookies from "js-cookie";

export default () => {
  let instance = axios.create({
    // send token
    headers: {
      Authorization: Cookies.get("token") ? Cookies.get("token") : null,
      OrganizationId: process.env.REACT_APP_ORGANIZATION_ID,
    },
    baseURL: BaseUrl.baseURL,
  });

  instance.interceptors.response.use(
    function (response) {
      // if (response.data.status === 400) {
      //   Cookies.remove("token");
      //   window.location = "/login";
      // }
      // if (response.data.status === 400) {
      //   Cookies.remove("token");
      //   window.location = "/login";
      // }

      // Any status code within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  return instance;
};
